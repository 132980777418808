import Constants from '../configuration/Constants';

class GoogleAuth {
  public static config(): Promise<void> {
    const promise = new Promise<void>((resolve) => {
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: Constants.GOOGLE_AUTH2_ID,
          cookie_policy: 'single_host_origin',
        });

        resolve();
      });
    });

    return promise;
  }

  public static signIn(): Promise<gapi.auth2.GoogleUser> {
    return gapi.auth2.getAuthInstance().signIn();
  }

  public static isSignedIn(): boolean {
    return gapi?.auth2?.getAuthInstance()?.isSignedIn?.get() || false;
  }

  public static getBasicProfile(): gapi.auth2.BasicProfile {
    return gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile();
  }

  public static getIdToken(): string {
    return gapi?.auth2?.getAuthInstance()?.currentUser?.get()?.getAuthResponse()?.id_token;
  }
}

export default GoogleAuth;
