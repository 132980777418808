class Messages {
    public static LOGIN_FAIL = 'Não foi possível realizar o login. Tente novamente mais tarde.';
    public static LOGIN_INVALID = 'Login inválido. Verifique os dados e tente novamente.';
    public static LOGIN_EXPIRED = 'Atenção, seu login expirou.';
    public static LOGOUT_FAIL = 'Não foi possível realizar o logout.';
    public static PASSWORD_UPDATE_FAIL = 'A senha informada não é a atual. Verifique as informações e tente novamente mais tarde.';
    public static PASSWORD_UPDATE_ERROR = 'Não foi possível realizar a alteração de senha. Tente novamente mais tarde.';
    public static PASSWORD_UPDATE_SUCCESS = 'Senha alterada com sucesso!';
    public static PASSWORD_RESET_FAIL = 'Não foi possível realizar a redefinição da senha. Entre em contato com os responsáveis para informações mais detalhadas.';
    public static PASSWORD_RESET_SUCCESS = 'Sua senha foi redefinida. O email enviado contém os próximos passos da redefinição.';

    public static STEP_FILL_ALL_FIELDS = 'Preencha todos os campos antes de prosseguir para a próxima etapa!';
    public static FILL_ALL_FIELDS = 'Preencha todos os campos antes de prosseguir!';

    public static CPF_INVALID = 'CPF inválido! Verifique o campo novamente.';
    public static EMAIL_ALREADY_TAKEN = 'Atenção! O email informado já está associado a um cadastro existente!';
    public static PASSWORD_MIN_LENGHT = 'Atenção! A senha deve ter no mínimo 6 caracteres.';
    public static PASSWORD_MISMATCH = 'Atenção! A senha e a confirmação não correpondem.';

    public static OPER_INSERT_ERROR = 'Não foi possível realizar o cadastro do operador. Tente novamente mais tarde.';

    public static MIN_AGE = 'Atenção! A idade mínima para o cadastro é 16 anos';

    // Login Google
    public static readonly LOGIN_GOOGLE_ERROR = 'Não foi possível realizar o login com sua conta Google.';
}

export default Messages;
