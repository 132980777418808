





































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { format, isValid } from 'date-fns';
import VueUtils from '../../assets/utils/VueUtils';
import DateUtil from '../../assets/utils/DateUtil';
import Util from '../../assets/utils/Util';

@Component({
  name: 'TkDate',
  components: {
  },
})
class TkDate extends Vue{
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly cssStyle: string | boolean;

  @Prop({ default: false })
  private readonly dense: boolean;

  @Prop({ default: false })
  private readonly disabled: boolean;

  @Prop({ default: false })
  private readonly error: boolean;

  @Prop({ default: false })
  private readonly hideDetails: boolean;

  @Prop({ default: false })
  private readonly hideDatepicker: boolean | string;

  @Prop({ default: '' })
  private readonly label: string;

  @Prop({ default: false })
  private readonly month: string | boolean;

  @Prop({ default: false })
  private readonly outlined: boolean;

  @Prop({ default: false })
  private readonly readonly: boolean;

  @Prop({ default: false })
  private readonly required: boolean;

  @Prop({ default: undefined })
  private readonly rules: (((value: any) => boolean) | boolean | string);

  @Prop({ default: true })
  private readonly showClear: boolean;

  @Prop({ default: true })
  private readonly showIcon: boolean;

  @Prop({ default: '' })
  private readonly value: string;

  @Prop({ default: false })
  private readonly validateOnBlur: string | boolean;
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  @Emit('updateSelectValue')
  private emitInput(value?: string) {
    return value || this.internalValue;
  }
  // #endregion

  // #region [ DATA ]
  private readonly dateInsertMode = 'insertMode';
  private readonly dateViewMode = 'viewMode';
  private readonly defaultStyle = 'padding-top: 8px !important; margin-top: 8px !important;';

  private showMenu = false;
  private internalValue = '';
  private activator: any = null;

  // Flag que diz se a data foi escolhida pelo VDatePicker
  private dateWasSelected = false;

  private dateModes = {
    insertMode: {
      icon: '',
    },
    viewMode: {
      icon: 'mdi-close',
    },
  };
  // #endregion

  // #region [ COMPUTED ]
  private get dateInYYYYMMDD(): string {
    if (this.internalValue === '') {
      return '';
    }

    const date = DateUtil.formatToYYYYMMDD(this.validDate);

    return date;
  }

  private get icon(): string {
    const showIcon = VueUtils.propertyIsTrue(this.showIcon);

    // Mostra icon para limpar campo quando em modo de visualização (uma data está/está sendo preenchida)
    if (this.showClear && this.dateMode === this.dateViewMode) {
      return this.dateModes.viewMode.icon;
    }

    const icon = showIcon ?
      'mdi-calendar' :
      undefined;

    return icon;
  }

  private get propMonth(): boolean {
    const month = VueUtils.propertyIsTrue(this.month);

    return month;
  }

  private get textReadOnly(): boolean {
    const readOnly = this.readonly || this.propMonth;

    return readOnly;
  }

  private get datePickerType(): string {
    const type = this.propMonth ?
      'month' :
      'date';

    return type;
  }

  private get dateMode() {
    const isDataSelected = this.internalValue;

    return isDataSelected ? this.dateViewMode : this.dateInsertMode;
  }

  private get validDate(): string {
    const adjustedDate = DateUtil.formatDate(this.internalValue);

    return adjustedDate;
  }

  private get changed(): boolean {
    return this.value !== this.internalValue;
  }

  private get style() {
    return this.cssStyle ? this.cssStyle : this.defaultStyle;
  }

  public get hideDatepickerProp(): boolean {
    return VueUtils.propertyIsTrue(this.hideDatepicker);
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange() {
    const date = this.value || '';

    this.internalValue = date;
  }
  // #endregion

  // #region [ METHODS ]
  private dateOnInput(unformattedDate: string) {
    let dateDMY;

    if (this.propMonth) {
      // Formats the YYYY-MM date to DD/MM/YYYY
      dateDMY = DateUtil.formatYYYYMMToDDMMYYYY(unformattedDate);
    } else {
      // Formats the YYYY-MM-DD date to DD/MM/YYYY
      dateDMY = DateUtil.formatToDDMMYYYY(unformattedDate);
    }

    this.internalValue = dateDMY;

    this.showMenu = false;
    this.dateWasSelected = true;

    this.emitInput();
  }

  private async fieldOnBlur() {
    if (this.internalValue !== '') {
      this.internalValue = this.validDate;
    }

    await Util.setTimeout(10);

    if (!this.dateWasSelected && this.changed) {
      this.emitInput();
    }
  }

  private fieldOnInput() {
    this.dateWasSelected = false;
  }

  private fieldOnTab() {
    this.showMenu = false;
  }

  private iconOnClick() {
    if (this.dateMode === this.dateViewMode) {
      // this.dateModes.viewMode.clearField();
      this.internalValue = '';
      this.emitInput();
    }
  }
  // #endregion
}

export default TkDate;
