import { NavigationGuardNext, Route } from 'vue-router';
import LoginUtil from '@/assets/ts/utils/LoginUtil';
import store from '@/store/store';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

class Authentication {
  private static LOGIN_ROUTE = '/login';
  private static DEFAULT_PATH = '/';

  public static beforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    const { LOGIN_TOKEN } = LoginUtil.getLoginItemsOnStorage();

    if (Authentication.validate(LOGIN_TOKEN)) {
      if (to.path !== Authentication.LOGIN_ROUTE) {
        next();
      } else {
        next(Authentication.DEFAULT_PATH);
      }

      if (Util.isEmptyOrBlank(store.getters.user)) {
        store.dispatch('user', LoginUtil.getLoginItemsOnStorage());
      }
      return;
    }

    localStorage.clear();

    // Verificação necessário para evitar loop
    if (to.path === Authentication.LOGIN_ROUTE) {
      next();
      return;
    }

    next(Authentication.LOGIN_ROUTE);
  }

  public static validate(token: string): boolean {
    return !!token;
  }
}

export default Authentication;
