















import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'BreakpointsHelper',
  components: {},
})
class BreakpointsHelper extends Vue {
  public debug = false;
  public center = true;

  public get breakpoint(): string {
    return this.$vuetify.breakpoint.name;
  }

  public mounted(): void {
    (window as any).bpDebugger = this;
  }
}

export default BreakpointsHelper;
