import { Theme } from 'vuetify/types/services/theme';

class ThemeUtil {
  public static readonly DEFAULT_THEME = 'light';
  public static readonly LS_KEY_NAME = 'TALENTOS_THEME';

  public static toggleDark(): void {
    const appTheme: Theme = (window as any).app.$vuetify.theme;
    appTheme.dark = !appTheme.dark;

    const theme = appTheme.dark ? 'dark' : 'light';
    this.setThemeInLocalStorage(theme);
  }

  public static setTheme(theme: string): void {
    const appTheme: Theme = (window as any).app.$vuetify.theme;
    const isDark = theme === 'dark';

    appTheme.dark = isDark;
  }

  public static setThemeByLocalStorage(): void {
    const lsTheme = localStorage.getItem(this.LS_KEY_NAME);
    const theme = lsTheme || this.DEFAULT_THEME;

    this.setTheme(theme);
  }

  public static setThemeInLocalStorage(theme: string): void {
    localStorage.setItem(this.LS_KEY_NAME, theme);
  }
}

export default ThemeUtil;
