import Vue from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import UrlUtil from '@/assets/ts/utils/UrlUtil';
import LoginUtil from '@/assets/ts/utils/LoginUtil';
import ConfirmationUtil from '@/assets/ts/utils/ConfirmationUtil';

HttpRequest.setUrl(UrlUtil.projectUrl);

const verifyTokenError = (errorCode: number): void => {
  const expiredSessionCodes = [100, 101];
  const sessionDeinedCodes = [10611];
  const onLogin = window.location.pathname.includes('login');

  if (expiredSessionCodes.includes(errorCode)) {
    if (!onLogin) {
      Vue.$toast.error('A sessão expirou. Entre novamente.');
      LoginUtil.resetTokenAndReload();
    }
  }

  if (sessionDeinedCodes.includes(errorCode)) {
    if (!onLogin) {
      ConfirmationUtil.deniedAccess(LoginUtil.logout);
    }
  }
};

const defaultOnFulfilled = (response: AxiosResponse) => {
  if (response?.data?.error) {
    verifyTokenError(response?.data?.errorCode);

    const error: any = new Error('Response has error!');
    error.response = response;

    throw error;
  }

  return response;
};

const defaultOnRejected = (error: AxiosError) => {
  verifyTokenError(error?.response?.data?.errorCode);
  throw error;
};

HttpRequest.addReponseInterceptor(defaultOnFulfilled, defaultOnRejected);
axios.interceptors.response.use(defaultOnFulfilled, defaultOnRejected);

// HttpRequest.addRequestInterceptor((request) => {
//   if (localStorage.TOKEN) {
//     request.headers.common['OAuth-Token'] = localStorage.TOKEN;
//   }

//   return request;
// });
