import { Vue, Component, Emit } from 'vue-property-decorator';
import { cpf } from 'cpf-cnpj-validator';
import { add, isAfter } from 'date-fns';
import TkField from '@/../bower_components/teknisavuecomponents/components/fields/TkField.vue';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import SingupController from '@/assets/ts/controller/SingupController';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import Messages from '@/assets/ts/utils/Messages';
import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';

@Component({
  name: 'LoginSignUp',
  components: {
    TkField,
    TkForm,
  },
})
class LoginSignUp extends Vue {
  public step = 1
  public dtNasc = '';

  public row: any = {
    STEP_1: {},
    STEP_2: {},
    STEP_3: {},
  };

  public definitions = {
    password: {
      length: 6,
    },
    age: {
      min: 16,
    },
  };

  public onFinishValidations: ((row: any) => boolean)[] = [
    this.dataNascimentoValidation,
  ];

  public stepValidations: any = {
    STEP_1: [
      this.passwordMatchValidation,
    ],
    STEP_2: [
      this.dataNascimentoValidation,
    ],
  };

  private reloadEstado = 0;
  private reloadMunicipio = 0;
  private loading = false;

  private get fieldsStep1(): Field[] {
    const fields = [
      {
        name: 'NMOPERADOR',
        label: 'Nome',
        type: 'text',
        size: 12,
        outlined: true,
        required: true,
      },
      {
        name: 'CPF',
        label: 'CPF',
        type: 'text',
        size: 12,
        outlined: true,
        mask: '###.###.###-##',
        required: true,
        onInput: this.cpfValidation,
      },
      {
        name: 'DSEMAILOPER',
        label: 'Email',
        type: 'text',
        size: 12,
        outlined: true,
        required: true,
        onInput: this.emailValidation,
      },
      {
        name: 'PASSWORD',
        label: 'Senha',
        type: 'password',
        size: 6,
        outlined: true,
        required: true,
        onInput: this.passwordValidation,
      },
      {
        name: 'PASSWORD_CONFIRMATION',
        label: 'Confirmar senha',
        type: 'password',
        size: 6,
        outlined: true,
        required: true,
      },
    ];

    return fields;
  }

  private get fieldsStep2(): Field[] {
    const fields = [
      {
        name: 'DTNASC',
        label: 'Data de Nascimento',
        type: 'date',
        dateStyle: '',
        size: 6,
        outlined: true,
        required: true,
      },
      {
        name: 'IDSEXOPESSOA',
        label: 'Sexo',
        type: 'select',
        size: 6,
        outlined: true,
        items: [
          {
            name: 'M',
            label: 'Maculino',
          },
          {
            name: 'F',
            label: 'Feminino',
          },
        ],
        descriptionField: 'label',
        valueField: 'name',
        required: true,
      },
      {
        name: 'CDESTACIVIL',
        label: 'Estado Civil',
        type: 'autocomplete',
        size: 12,
        outlined: true,
        valueField: 'CDESTACIVIL',
        descriptionField: 'NMESTACIVIL',
        route: 'getEstadocivil',
        reloadOnCreation: true,
        required: true,
      },
      {
        name: 'CDPAIS',
        label: 'Nacionalidade',
        size: 12,
        outlined: true,
        type: 'autocomplete',
        valueField: 'CDPAIS',
        descriptionField: 'DSNACIONALIDADE',
        route: 'getNacionalidade',
        reloadOnCreation: true,
        required: true,
        defaultDescription: '0055',
      },
      {
        name: 'NRGRAUINSTR',
        label: 'Grau de Instrução',
        type: 'autocomplete',
        size: 12,
        outlined: true,
        valueField: 'NRGRAUINSTR',
        descriptionField: 'NMGRAUINSTR',
        route: 'getGrauinstrucao',
        reloadOnCreation: true,
        required: true,
      },
      {
        name: 'IDPOSSUIFILHO',
        label: 'Filhos?',
        type: 'select',
        size: 12,
        outlined: true,
        items: [
          {
            name: 'N',
            label: 'Não',
          },
          {
            name: 'S',
            label: 'Sim',
          },
        ],
        descriptionField: 'label',
        valueField: 'name',
        required: true,
      },
    ];

    return fields;
  }

  private get fieldsStep3(): Field[] {
    const fields = [
      {
        name: 'CDPAIS_ENDERECO',
        label: 'País',
        size: 12,
        outlined: true,
        type: 'autocomplete',
        valueField: 'CDPAIS',
        descriptionField: 'NMPAIS',
        route: 'getPais',
        reloadOnCreation: true,
        required: true,
        onInput: this.enderecoOnInput,
        readonly: this.loading,
      },
      {
        name: 'SGESTADO_ENDERECO',
        label: 'Estado',
        type: 'autocomplete',
        size: 12,
        outlined: true,
        valueField: 'SGESTADO',
        descriptionField: 'NMESTADO',
        route: 'getEstado',
        reloadOnCreation: true,
        filters: this.filter,
        triggerReload: this.reloadEstado,
        required: true,
        onInput: this.enderecoOnInput,
        readonly: this.loading,
      },
      {
        name: 'CDMUNICIPIO_ENDERECO',
        label: 'Cidade',
        type: 'autocomplete',
        size: 12,
        outlined: true,
        valueField: 'CDMUNICIPIO',
        descriptionField: 'NMMUNICIPIO',
        route: 'getMunicipio',
        reloadOnCreation: true,
        filters: this.filter,
        required: true,
        triggerReload: this.reloadMunicipio,
        readonly: this.loading,
      },
    ];

    return fields;
  }

  private get filter(): any {
    const { STEP_3 } = this.row;
    return {
      CDPAIS: STEP_3.CDPAIS_ENDERECO,
      SGESTADO: STEP_3.SGESTADO_ENDERECO,
    };
  }

  @Emit('back')
  private emitBack(
    email: string = null,
    password: string = null,
  ): { email: string, password: string } {
    return { email, password };
  }

  public goBack(): void {
    this.emitBack();
  }

  public async finishSignUp(): Promise<void> {
    const consolidatedRow = { ...this.row.STEP_1, ...this.row.STEP_2, ...this.row.STEP_3 };

    try {
      this.loading = true;
      const success = await SingupController.setOperadorAcesso(consolidatedRow);

      if (success) {
        this.resetSteps();
        this.emitBack(consolidatedRow.DSEMAILOPER, consolidatedRow.PASSWORD);
        return;
      }

      this.$toast.error(Messages.OPER_INSERT_ERROR);
    } catch (err) {
      this.$toast.error(Messages.OPER_INSERT_ERROR);
    } finally {
      this.loading = false;
    }
  }

  public async finishSignUpOnClick(): Promise<void> {
    if (!this.onNextStep()) {
      return;
    }

    (this as any).$confirm(
      {
        message: 'Deseja finalizar o cadastro?',
        button: {
          no: 'Não',
          yes: 'Sim',
        },
        callback: (confirm: boolean) => {
          if (confirm) {
            this.finishSignUp();
          }
        },
      },
    );
  }

  public enderecoOnInput(): void {
    this.reloadEstado += 1;
    this.reloadMunicipio += 1;
  }

  public previousStep(): void {
    this.step -= 1;
  }

  public onNextStep(): boolean {
    const currentStep = `STEP_${this.step}`;

    // Row empty validations
    const currentRow = this.row[currentStep];
    const isRowValid = currentRow.validate();
    if (!isRowValid) {
      this.$toast.warning(Messages.STEP_FILL_ALL_FIELDS);
      return false;
    }

    // Step validations
    const validations = this.stepValidations[currentStep];
    if (!validations) {
      return true;
    }

    const isFinishValidationsValid = validations.reduce(
      (accumulator: any, validation: any) => accumulator && validation(this.row), true,
    );

    if (!isFinishValidationsValid) {
      return false;
    }

    return true;
  }

  public nextStep(): void {
    if (!this.onNextStep()) {
      return;
    }

    this.step += 1;
  }

  public resetSteps(): void {
    this.row.STEP_1 = {};
    this.row.STEP_2 = {};
    this.row.STEP_3 = {};

    this.step = 1;
  }

  public cpfValidation(value: string): void {
    if (!cpf.isValid(value)) {
      setTimeout(() => {
        this.row.STEP_1.CPF = '';
      });
      this.$toast.error(Messages.CPF_INVALID);
    }
  }

  public async emailValidation(value: string): Promise<void> {
    const response = await HttpRequest.get('isEmailTaken', {
      requestType: 'FilterData',
      DSEMAILOPER: value,
    });

    const isEmailTaken = response?.data?.dataset?.data[0];
    if (isEmailTaken) {
      this.row.STEP_1.DSEMAILOPER = '';
      this.$toast.error(Messages.EMAIL_ALREADY_TAKEN);
    }
  }

  public passwordValidation(value: string): void {
    if (value.length < this.definitions.password.length) {
      setTimeout(() => {
        this.row.STEP_1.PASSWORD = '';
        this.row.STEP_1.PASSWORD_CONFIRMATION = '';
      });
      this.$toast.error(Messages.PASSWORD_MIN_LENGHT);
    }
  }

  public dataNascimentoValidation(row: { STEP_2: { DTNASC: string } }): boolean {
    const date = DateUtil.getDateDDMMYYYY(row.STEP_2.DTNASC);
    const minDateRequired = add(date, { years: this.definitions.age.min });
    if (isAfter(minDateRequired, new Date())) {
      this.$toast.error(Messages.MIN_AGE);
      return false;
    }

    return true;
  }

  public passwordMatchValidation(
    row: { STEP_1: { PASSWORD: string, PASSWORD_CONFIRMATION: string } },
  ): boolean {
    if (row.STEP_1.PASSWORD !== row.STEP_1.PASSWORD_CONFIRMATION) {
      this.$toast.error(Messages.PASSWORD_MISMATCH);
      return false;
    }

    return true;
  }
}

export default LoginSignUp;
