







































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import Util from '../../assets/utils/Util';
import VueUtils from '../../assets/utils/VueUtils';
import Http from '../../assets/utils/HttpRequest';
import type SelectMenuProps from '../../assets/interfaces/SelectMenuProps';

@Component
export default class TkSelect extends Vue {
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly autocomplete!: boolean;

  @Prop({ default: 'off' })
  private readonly browserAutocomplete: string;

  @Prop({ default: '' })
  private readonly color: string;

  @Prop({ default: false })
  private readonly chips: boolean;

  @Prop({ default: '' })
  private readonly defaultDescription: any;

  @Prop({ default: false })
  private readonly dense!: boolean;

  @Prop({ default: false })
  private readonly deletableChips!: boolean;

  @Prop({ default: 'text' })
  private readonly descriptionField!: string;

  @Prop({ default: false })
  private readonly disabled!: boolean;

  @Prop({ default: false })
  private readonly error!: boolean;

  @Prop({ default: undefined })
  private readonly errorMessages: string | string[];

  @Prop({ default: undefined })
  private readonly filter: (item: any, queryText: string, itemText: string) => boolean;

  @Prop({ default: () => new Object() })
  private readonly filters!: any;

  @Prop({ default: false })
  private readonly hideDetails!: boolean;

  @Prop({ default: false })
  private readonly hideEmptyValue: boolean;

  @Prop({ default: (): any => [] })
  private readonly items!: [];

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: false })
  private readonly loading!: boolean;

  @Prop({ default: undefined })
  private readonly menuProps: SelectMenuProps;

  @Prop({ default: false })
  private readonly multiple!: string | boolean;

  @Prop({ default: undefined })
  private readonly noDataText: string;

  @Prop({ default: false })
  private readonly outlined: boolean;

  @Prop({ default: false })
  private readonly readonly: boolean;

  @Prop({ default: false })
  private readonly reloadOnCreation!: boolean;

  @Prop({ default: false })
  private readonly reloadOnClick!: boolean;

  @Prop({ default: false })
  private readonly required: boolean;

  @Prop({ default: false })
  private readonly returnObject: boolean;

  @Prop({ default: '' })
  private readonly route!: string;

  @Prop({ default: '' })
  private readonly selectDescription!: string;

  @Prop({ default: 0 })
  private readonly triggerReload!: number;

  @Prop({ default: undefined })
  private readonly value: string | number;

  @Prop({ default: 'value' })
  private readonly valueField!: string;
  // #endregion

  // #region [ EVENTS ]
  @Emit('after-reload')
  private emitAfterReload(rows: any[]) {};

  @Emit('blur')
  private emitBlur() {};

  @Emit('change')
  private emitChange(value: any) {};

  @Emit('click')
  private emitClick() {};

  @Emit('focus')
  private emitFocus() {};

  @Emit('input')
  private emitInput(chosenItem: any) {};

  @Emit('updateSelectValue')
  private emitUpdateSelectValue(value: any) {};
  // #endregion

  // #region [ DATA ]
  private options: any = [];

  private currentRequestCode = 0;

  private internalValue: string | number = -1;

  private reloaded: boolean = true;

  private open = false;
  private focused = false;
  // #endregion

  // #region [ COMPUTED ]
  private get isAutocomplete() {
    const selectIsAutoComplete =
      this.autocomplete === true ||
      this.autocomplete.toString() === '';

    return selectIsAutoComplete;
  }

  private get propMultiple(): boolean {
    const multiple = VueUtils.propertyIsTrue(this.multiple);

    return multiple;
  }

  private get internalDescriptionField(): string {
    if (this.selectDescription && this.open) {
      return this.selectDescription;
    }

    return this.descriptionField;
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('items')
  private itemsOnChange(newValue: any[]) {
    this.options = Util.deepCopy(newValue);
  }

  @Watch('triggerReload')
  private onReloadTrigger() {
    this.$forceUpdate();
    this.reload();
    this.reloaded = true;
  }

  @Watch('value', { immediate: true })
  private valueOnChange() {
    this.internalValue = this.value;
    this.initializeValue();
  }

  @Watch('filters', { immediate: true })
  private filtersOnChange() {
    this.reloaded = true;
  }

  @Watch('disabled')
  private disabledOnChange() {
    this.setAutocompleteAttribute();
  }

  /*@Watch('internalValue', { immediate: true })
  private internalValueOnChange() {
    this.$emit("updateSelectValue",this.internalValue);
  }*/

  // #endregion

  // #region [ LIFECYCLE ]
  private created() {
    if (this.items) {
      this.options = Util.deepCopy(this.items);
    }
  }

  private mounted() {
    if (this.route && this.reloadOnCreation) {
      this.reload();
    }

    this.setAutocompleteAttribute();
  }

  private initializeValue() {
    if(Util.objectIsEmpty(this.options) && !this.reloadOnCreation && this.reloadOnClick){
        this.$set(this.options,0,{});
        this.$set(this.options[0], this.valueField, this.value);
        this.$set(this.options[0], this.descriptionField, this.defaultDescription);
      }
  }
  // #endregion

  // #region [ METHODS ]
  private async reload() {
    if (!this.route) {
      return;
    }

    const params = {
      requestType: 'FilterData',
      ...this.filters,
    };

    const requestCode = this.getNextRequestCode();

    const response = await Http.get(this.route, params);

    if (requestCode === this.currentRequestCode) {
      this.options = response.data.dataset[Object.keys(response.data.dataset)[0]];
      this.emitAfterReload(this.options);
    }
  }

  private emitInputEvent(chosenItem: any): void {
    this.open = false;

    this.internalValue = chosenItem;
    this.emitInput(chosenItem);
    this.emitUpdateSelectValue(this.internalValue);
  }

  private setAutocompleteAttribute() {
    const thisElement: Element = (this as any).$el;
    const fieldElement = Util.findChildRecursive(this.$el, 'input');

    if (!fieldElement) {
      return;
    }

    fieldElement.setAttribute('autocomplete', this.browserAutocomplete);
  }

  private onFocus(): void {
    this.focused = true;

    this.checkReloadOnClick();
    this.emitFocus();
  }

  private onBlur(): void {
    this.focused = false;
    this.open = false;

    this.emitBlur();
  }

  private onChange(value: any) {
    this.emitChange(value);
  }

  private onClick(): void {
    this.open = true;

    this.emitClick();
  }

  private checkReloadOnClick(): void {
    if (this.reloadOnClick && this.route && this.reloaded) {
      this.reload();
      this.reloaded = false;
    }
  }

  private getNextRequestCode(): number {
    this.currentRequestCode++;
    return this.currentRequestCode;
  }
  // #endregion
}
