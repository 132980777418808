import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import FileUtils from '@/../bower_components/teknisavuecomponents/assets/utils/FileUtils';

import LoginController from '@/assets/ts/controller/LoginController';

@Component({
  name: 'NovaFoto',
  components: {
    TkForm,
  },
})
class NovaFoto extends Vue {
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ STORE ]

  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput() {
    return this.internalValue;
  }

  @Emit('save')
  private emitSave() {}
  // #endregion

  // #region [ DATA ]
  public loading = {
    save: false,
    file: false,
  };

  public internalValue = false;

  public row: any = {};

  private base64 = '';

  private fileFieldError: string | boolean = true;
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'FILE',
        label: 'Arquivo',
        type: 'file',
        size: 12,
        onInput: this.fileOnChange,
        required: true,
        accept: 'image/*',
        rules: [
          this.fileFieldError,
        ],
      },
    ];
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value;

    if (this.internalValue) {
      this.row = {};
      this.base64 = '';
    }
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public closeDialog(): void {
    this.internalValue = false;
    this.emitInput();
  }

  public async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    const clip: HTMLCanvasElement = (this.$refs.cropper as any).clip();
    const base64 = clip.toDataURL();

    this.loading.save = true;

    try {
      const row = {
        ...this.row,
        FILE: base64,
      };

      await LoginController.setFoto(row);
      this.$toast.success('Foto atualizada com sucesso!');

      this.closeDialog();
      this.emitSave();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const isValid = !!this.base64;

    if (!isValid) {
      this.$toast.error('Preencha todos os campos obrigatórios!');
    }

    return isValid;
  }

  private async fileOnChange(value: any) {
    if (value instanceof File) {
      if (!this.fileNameIsValid(value.name)) {
        this.fileFieldError = 'O nome do arquivo excede o limite de 100 caracteres!.';
        this.clearFile();

        return;
      }

      this.fileFieldError = true;
      await this.createBase64File(value);
      this.row.NMANEXO = value.name;
    } else {
      this.clearFile();
    }
  }

  private clearFile(): void {
    this.base64 = '';
    this.row.NMANEXO = null;
    this.row.FILE = null;
  }

  private fileNameIsValid(
    fileName: string,
  ): boolean {
    if (fileName.length >= 100) {
      return false;
    }

    return true;
  }

  private async createBase64File(file: File) {
    this.loading.file = true;

    try {
      const fileString = await FileUtils.toBase64(file);
      this.base64 = fileString;
    } catch (err) {
      const message = 'Não foi possível carregar o arquivo';
      this.$toast.warning(message);

      throw err;
    } finally {
      this.loading.file = false;
    }
  }
  // #endregion
}

export default NovaFoto;
