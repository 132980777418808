import Constants from '../configuration/Constants';
// import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

class LinkedInAuth {
  public static readonly AUTHORIZATION_URL = 'https://www.linkedin.com/oauth/v2/authorization?';
  public static readonly STATE = '8830db56e65d812a81b72380b605c218';

  public static readonly REDIRECT_URL =
    document.location.origin +
    document.location.pathname;

  public static readonly LS_KEY = 'LINKEDIN';

  public static authorizationCode: string = null;

  public static authenticationCallback: (authCode: string) => void = null;
  public static closedCallback: () => void = null;

  private static authenticationWindow: Window = null;

  public static isLinkedInCallback(): boolean {
    const { href } = document.location;
    const isInCallback = href.includes(`state=${this.STATE}`);

    return isInCallback;
  }

  public static authenticate(): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.openAuthenticationWindow();

      this.setAuthenticationCallback((authCode) => {
        resolve(authCode);
      });

      this.setClosedCallback(() => {
        const error = new Error('Popup closed by user');
        reject(error);
      });
    });

    return promise;
  }

  public static getAuthorizationUrl(): string {
    const scopeItems = [
      'r_liteprofile',
      'r_emailaddress',
    ];

    const scope = scopeItems.join('%20');

    const params = {
      client_id: Constants.LINKEDIN_CLIENT_ID,
      redirect_uri: this.REDIRECT_URL,
      state: this.STATE,
      response_type: 'code',
      scope,
    };

    const queryParams = HttpRequest.createInlineQuery(params);

    const url = this.AUTHORIZATION_URL + queryParams;

    return url;
  }

  public static openAuthenticationWindow(): Window {
    const authUrl = this.getAuthorizationUrl();

    const options = [
      'scrollbars=no',
      'resizable=no',
      'status=no',
      'location=no',
      'toolbar=no',
      'menubar=no',
      'width=600',
      'height=600',
      'left=100',
      'top=100',
    ];

    const optionsStr = options.join(',');
    localStorage.removeItem(this.LS_KEY);

    this.authenticationWindow = window.open(authUrl, 'linkedin', optionsStr);

    const interval = setInterval(() => {
      const popupIsClosed = this.authenticationWindow.closed;
      const authenticated = !!localStorage.getItem(this.LS_KEY);

      if (popupIsClosed) {
        clearInterval(interval);

        if (this.closedCallback && !authenticated) {
          this.closedCallback();
        }
      }
    }, 1000);

    return this.authenticationWindow;
  }

  public static async verifyToken(): Promise<void> {
    const query = document.location.search;
    const params = HttpRequest.getParamsFromQuery(query);

    const { code, state } = params;

    if (state === this.STATE) {
      localStorage.setItem(this.LS_KEY, code);
    }

    await Util.setTimeout(100);
    window.close();
  }

  public static setAuthenticationCallback(
    callback: (authCode: string) => void,
  ): void {
    this.authenticationCallback = callback;
  }

  public static setClosedCallback(
    callback: () => void,
  ): void {
    this.closedCallback = callback;
  }
}

addEventListener('storage', () => {
  const authorizationCode = localStorage.getItem(LinkedInAuth.LS_KEY);

  if (authorizationCode) {
    LinkedInAuth.authorizationCode = authorizationCode;

    if (LinkedInAuth.authenticationCallback) {
      LinkedInAuth.authenticationCallback(authorizationCode);
    }
  }
});

export default LinkedInAuth;
