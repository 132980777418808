import Vue from 'vue';
import { AxiosRequestConfig } from 'axios';
import FileData from '@/../bower_components/teknisavuecomponents/assets/interfaces/FileData';
import Constants from '@/assets/ts/configuration/Constants';
import UrlUtil from '@/assets/ts/utils/UrlUtil';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import LinkedInAuth from '../utils/LinkedInAuth';

class LoginController {
  public static async requestLogin(email: string, password: string): Promise<any> {
    const filter = [
      {
        name: 'EMAIL',
        operator: '=',
        value: email,
      },
      {
        name: 'PASSWORD',
        operator: '=',
        value: password,
      },
      {
        name: 'PRODUCT_ID',
        operator: '=',
        value: Constants.PRODUCT_ID,
      },
      {
        name: 'REQUESTER_URL',
        operator: '=',
        value: document.location.href,
      },
      {
        name: 'ATTEMPTS',
        operator: '=',
        value: 1,
      },
    ];

    const params = {
      requestType: 'FilterData',
      filter,
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
      baseURL: UrlUtil.loginUrl,
    };

    const response = await HttpRequest.post('/login', params, false, null, config);
    ExpectedError.throwResponseError(response);

    return response.data;
  }

  public static async requestStartSession(user: string, token: string): Promise<any> {
    const filter = [
      {
        name: 'USER',
        operator: '=',
        value: user,
      },
      {
        name: 'IS_LOGGED',
        operator: '=',
        value: 'true',
      },
      {
        name: 'LOGIN_TOKEN',
        operator: '=',
        value: token,
      },
    ];

    const params = {
      requestType: 'FilterData',
      filter,
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const response = await HttpRequest.post('/lib_startSession', params, false, null, config);
    return response?.data?.dataset?.system;
  }

  public static async requestLogout(user: string, token: string): Promise<any> {
    const filter = [
      {
        name: 'PROJECT',
        operator: '=',
        value: Constants.PRODUCT_ID,
      },
      {
        name: 'USER',
        operator: '=',
        value: user,
      },
      {
        name: 'LOGIN_TOKEN',
        operator: '=',
        value: token,
      },
    ];

    const params = {
      requestType: 'FilterData',
      filter,
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const loader = Vue.$loading.show();

    try {
      const response = await HttpRequest.post('/lib_logout', params, false, null, config);
      return response?.data?.dataset?.system;
    } finally {
      loader.hide();
    }
  }

  public static async requestUpdatePassword(
    email: string,
    actualPassword: string,
    newPassword: string,
  ): Promise<any> {
    const filter = [
      {
        name: 'ACTUAL_PASSWORD',
        operator: '=',
        value: actualPassword,
      },
      {
        name: 'NEW_PASSWORD',
        operator: '=',
        value: newPassword,
      },
      {
        name: 'CONFIRM_NEW_PASSWORD',
        operator: '=',
        value: newPassword,
      },
      {
        name: 'EMAIL',
        operator: '=',
        value: email,
      },
    ];

    const config: AxiosRequestConfig = {
      withCredentials: true,
      baseURL: UrlUtil.loginUrl,
    };

    const params = {
      requestType: 'FilterData',
      filter,
    };

    const response = await HttpRequest.post('/updatePassword', params, false, null, config);
    return !response?.data.error;
  }

  public static async requestResetPassword(
    email: string,
  ): Promise<number> {
    const filter = [
      {
        name: 'EMAIL',
        operator: '=',
        value: email,
      },
    ];

    try {
      const config: AxiosRequestConfig = {
        withCredentials: true,
        baseURL: UrlUtil.loginUrl,
      };

      const params = {
        requestType: 'FilterData',
        filter,
      };

      const response = await HttpRequest.post('/requestNewPassword', params, false, null, config);
      return response?.data?.errorCode;
    } catch (err: any) {
      return err?.response?.data?.errorCode;
    }
  }

  public static async requestLoginGoogle(
    idToken: string,
  ): Promise<any> {
    const route = 'loginGoogle';

    const params = {
      requestType: 'Row',
      row: {
        idtoken: idToken,
        REQUESTER_URL: document.location.href,
      },
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const response = await HttpRequest.post(route, params, false, null, config);
    ExpectedError.throwResponseError(response);

    return response.data;
  }

  public static async requestLoginLinkedIn(
    authCode: string,
  ): Promise<any> {
    const route = 'loginLinkedIn';

    const params = {
      requestType: 'Row',
      row: {
        authCode,
        REQUESTER_URL: LinkedInAuth.REDIRECT_URL,
      },
    };

    const config: AxiosRequestConfig = {
      withCredentials: true,
    };

    const response = await HttpRequest.post(route, params, false, null, config);
    ExpectedError.throwResponseError(response);

    return response.data;
  }

  public static async getFoto(): Promise<FileData | false> {
    const route = 'getFoto';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const foto = response.data.dataset.data.foto;
      return foto;
    } catch (err) {
      const defaultMessage = 'Não foi possível carregar a foto de perfil!';
      const message = ExpectedError.getErrorMessage(err, defaultMessage);
      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async setFoto(
    row: any,
  ): Promise<void> {
    const route = 'setFoto';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      const defaultMessage = 'Não foi possível salvar a foto de perfil!';
      const message = ExpectedError.getErrorMessage(err, defaultMessage);
      Vue.$toast.error(message);
      throw err;
    }
  }
}

export default LoginController;
