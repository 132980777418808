import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '../views/Dashboard/Dashboard.vue';
import Authentication from './Authentication';
import Login from '../views/Login/Login.vue';
import AppInstances from '@/assets/ts/configuration/AppInstances';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/meu-perfil',
    name: 'Meu Perfil',
    component: () => import(/* webpackChunkName: "meu-perfil" */ '../views/MeuPerfil/MeuPerfil.vue'),
  },
  {
    path: '/vagas',
    name: 'Vagas',
    component: () => import(/* webpackChunkName: "meu-perfil" */ '../views/Vagas/Vagas.vue'),
  },
];

const router = new VueRouter({
  routes,
});

AppInstances.router = router;

router.beforeEach(Authentication.beforeEach);

export default router;
