interface TypeRelation {
  color: string;
  icon: string;
  timeout: number;
}

interface TypesRelations {
  [index: string]: TypeRelation;
}

class SnackbarUtils {
  public static toast: any;

  public static readonly TYPE_SUCCESS = 'success';
  public static readonly TYPE_ERROR = 'error';
  public static readonly TYPE_ALERT = 'alert';
  public static readonly TYPE_INFO = 'info';
  public static readonly TYPE_INFO2 = 'info2';

  private static readonly typesRelations: TypesRelations = {
    [SnackbarUtils.TYPE_SUCCESS]: {
      color: 'success',
      icon: 'mdi-check-circle-outline',
      timeout: 5000,
    },
    [SnackbarUtils.TYPE_ERROR]: {
      color: 'error',
      icon: 'mdi-alert-circle-outline',
      timeout: 8000,
    },
    [SnackbarUtils.TYPE_ALERT]: {
      color: 'yellow darken-3',
      icon: 'mdi-alert-outline',
      timeout: 8000,
    },
    [SnackbarUtils.TYPE_INFO]: {
      color: 'info',
      icon: 'mdi-info',
      timeout: 7000,
    },
    [SnackbarUtils.TYPE_INFO2]: {
      color: '#3A9FBC',
      icon: 'mdi-info',
      timeout: 7000,
    },
  }

  public static showMessage(
    message : string,
    type = this.TYPE_SUCCESS,
    timeout ?: number | string,
  ) {
    const typeRelation =
        SnackbarUtils.typesRelations[type];

    timeout = timeout === undefined ?
      typeRelation.timeout :
      timeout;

    this.toast(message, {
        ...typeRelation,
        timeout,
        showClose: true
    });
  }
}

export default SnackbarUtils;
