import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import LoginUtil from '@/assets/ts/utils/LoginUtil';

@Component({
  name: 'ResetPassword',
  components: {},
})
class ResetPassword extends Vue {
  // #region [ PROPS ]
  @Prop({ default: '' })
  private readonly email: string;
  // #endregion

  // #region [ DATA ]
  private resetEmail = '';
  private loading = false;
  // #endregion

  // #region [ EVENTS ]
  @Emit('back')
  private emitBack(
    email: string = null,
    password: string = null,
  ): { email: string, password: string } {
    this.resetEmail = '';
    return { email, password };
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('email', { immediate: true })
  private emailOnChange(): void {
    this.resetEmail = this.email;
  }
  // #endregion

  // #region [ METHODS ]
  public goBack(): void {
    this.emitBack();
  }

  public async reset(): Promise<void> {
    if (this.resetEmail === '') {
      return;
    }

    try {
      this.loading = true;
      const isPasswordReset = await LoginUtil.resetPassword(this.resetEmail);
      if (isPasswordReset) {
        setTimeout(() => {
          this.emitBack(this.resetEmail);
        }, 2000);
      }
    } finally {
      this.loading = false;
    }
  }
  // #endregion
}

export default ResetPassword;
