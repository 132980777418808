import axios, { AxiosInstance } from 'axios';
import Constants from '@/assets/ts/configuration/Constants';

class UrlUtil {
  public static projectUrl = Constants.PROJECT_PATH + Constants.BACKEND_PATH;
  public static loginUrl = Constants.PROJECT_PATH + Constants.LOGIN_BACKEND_PATH;

  public static getLoginAxios(): AxiosInstance {
    return axios.create({
      baseURL: this.loginUrl,
    });
  }

  public static getProjectAxios(): AxiosInstance {
    return axios.create({
      baseURL: this.projectUrl,
    });
  }
}

export default UrlUtil;
