import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import type Field from '../../assets/interfaces/Field';
import VueUtils from '../../assets/utils/VueUtils';
import SnackbarUtils from '../../assets/utils/SnackbarUtils';

import TkDate from './TkDate.vue';
import TkSelect from './TkSelect.vue';
import StringUtils from '../../assets/utils/StringUtils';

@Component({
  name: 'TkField',
  components: {
    TkDate,
    TkSelect,
  }
})
class TkField extends Vue {
  private static readonly TYPES = [
    'autocomplete',
    'checkbox',
    'date',
    'file',
    'select',
    'text',
    'textarea',
    'number',
    'password',
    'banner',
    'radio',
  ];

  // #region [ PROPS ]
  @Prop({ default: true })
  private readonly dense: boolean | string;

  @Prop({ default: false })
  private readonly outlined: any;

  @Prop({ required: true })
  private readonly field: Field;

  @Prop({ default: 'edit' })
  private readonly mode: string;

  @Prop({ default: false })
  private readonly hideDetails: boolean;

  @Prop({ default: null })
  private readonly value: any;

  @Prop({ default: null })
  private readonly defaultDescription: any;
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput(value: any) {
  }

  @Emit('blur')
  private emitBlur(value: any) {
  }
  // #endregion

  // #region [ DATA ]
  private internalValue: any = null;

  private password_defaults = {
    iconColor: 'grey',
    typeOnHide: 'password',
    typeOnDisplay: 'text',
    iconOnHide: 'mdi-eye-off',
    iconOnDisplay: 'mdi-eye',
  };

  private passwordHandler = {
    currentType: 'password',
    toggleHideDisplay: this.toggleHideDisplay,
    showHideDisplayIcon: this.showHideDisplayIcon,
  };
  // #endregion

  // #region [ COMPUTED ]
  private get fieldIsDisabled() {
    const disabled =
      this.field.readonly === true ||
      this.mode === 'view';

    return disabled;
  }

  private get propDense() {
    const denseValue = VueUtils.propertyIsTrue(this.dense);

    return denseValue;
  }

  private get outlinedProp(): boolean {
    const isOutlined =
      VueUtils.propertyIsTrue(this.outlined) ||
      VueUtils.propertyIsTrue(this.field.outlined);

    return isOutlined;
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private created() {
    this.validateType();
  }
  // #endregion

  //#region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange() {
    this.internalValue = this.value;
  }
  //#endregion

  // #region [ METHODS ]
  private fieldOnInput(value: any) {
    this.emitInput(value);
  }

  private validateType() {
    const typeExists = TkField.TYPES.some((type) => {
      return this.field.type === type;
    });

    if (!typeExists) {
      const message = `Não existe o tipo de campo "${this.field.type}"`;
      // SnackbarUtils.showMessage(message, 'alert', 5000);

      throw new Error(message);
    }
  }

  private fieldOnBlur(event: any) {
    this.emitBlur(this.internalValue);

    const onBlurCallback = this.field.onBlur;

    if (typeof(onBlurCallback) === 'function') {
      onBlurCallback(this.internalValue);
    }
  }

  private fieldOnFocus(event: any) {
    const onFocusCallback = this.field.onFocus;

    if (typeof(onFocusCallback) === 'function') {
      onFocusCallback(this.internalValue);
    }
  }

  private toggleHideDisplay() {
    this.passwordHandler.currentType =
      this.passwordHandler.currentType === this.password_defaults.typeOnHide ?
        this.password_defaults.typeOnDisplay :
        this.password_defaults.typeOnHide;
  }

  private showHideDisplayIcon(): string {
    const icon =
      this.passwordHandler.currentType === this.password_defaults.typeOnHide ?
        this.password_defaults.iconOnHide :
        this.password_defaults.iconOnDisplay;

    return icon;
  }

  public autocompleteFilter(item: any, queryText: string, itemText: string): boolean {
    const filter = this.field.filter === undefined ?
      StringUtils.includesIgnoreSpecial(itemText, queryText) :
      undefined;

    return filter;
  }
  // #endregion
}

export default TkField;
