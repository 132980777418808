import Vue from 'vue';
import LoginController from '@/assets/ts/controller/LoginController';
import Messages from '@/assets/ts/utils/Messages';
import GoogleAuth from './GoogleAuth';
import LoginErrors from './LoginErrors';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import LinkedInAuth from './LinkedInAuth';

class LoginUtil {
  public static LS_PROP = 'LIB';
  public static LOGIN_EXPIRED_MESSAGE = 'RESET_PASSWORD';

  public static async login(
    email: string,
    password: string,
    onExpired: () => void = () => null,
  ): Promise<void> {
    try {
      const loginRes: any = await LoginController.requestLogin(email, password);

      await LoginUtil.afterLogin(loginRes, onExpired);
    } catch (err: any) {
      const message = err.response ?
        LoginErrors.getErrorMessage(err.response.data.errorCode) :
        err.message;

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async logout(): Promise<void> {
    try {
      const { LOGIN_TOKEN, LOGIN_USER } = LoginUtil.getLoginItemsOnStorage();
      await LoginController.requestLogout(LOGIN_USER, LOGIN_TOKEN);
    } catch (err) {
      Vue.$toast.error(Messages.LOGOUT_FAIL);
    } finally {
      LoginUtil.resetTokenAndReload();
    }
  }

  public static async updatePassword(
    actualPassword: string,
    newPassword: string,
    specificEmail: string = null,
  ): Promise<boolean> {
    try {
      const { EMAIL } = LoginUtil.getLoginItemsOnStorage();
      const finalEmail = specificEmail || EMAIL;

      const success = await LoginController.requestUpdatePassword(
        finalEmail,
        actualPassword,
        newPassword,
      );

      if (!success) {
        Vue.$toast.error(Messages.PASSWORD_UPDATE_FAIL);
        return false;
      }
      Vue.$toast.success(Messages.PASSWORD_UPDATE_SUCCESS);
      return true;
    } catch (err) {
      Vue.$toast.error(Messages.PASSWORD_UPDATE_ERROR);
    }

    return false;
  }

  public static async resetPassword(
    email: string,
  ): Promise<boolean> {
    try {
      const responseCode = await LoginController.requestResetPassword(email);
      if (Util.isEmptyOrBlank(responseCode)) {
        Vue.$toast.success(Messages.PASSWORD_RESET_SUCCESS);
        return true;
      }

      const message = LoginErrors.ERRORS_MESSAGES[responseCode] || Messages.PASSWORD_RESET_FAIL;
      Vue.$toast.error(message);
      return false;
    } catch (err) {
      Vue.$toast.error(Messages.PASSWORD_RESET_FAIL);
    }

    return false;
  }

  public static setLoginItemsOnStorage(systemResponse: {[prop: string]: any}): void {
    localStorage.setItem(LoginUtil.LS_PROP, JSON.stringify(systemResponse));
    localStorage.setItem('TOKEN', systemResponse.LOGIN_TOKEN);
  }

  public static getLoginItemsOnStorage(): any {
    const localStorageItem = localStorage.getItem(LoginUtil.LS_PROP);
    return JSON.parse(!localStorageItem || localStorageItem === 'undefined' ? '{}' : localStorageItem) ?? {};
  }

  public static async loginWithGoogle(): Promise<boolean> {
    try {
      await GoogleAuth.signIn();

      const idToken = GoogleAuth.getIdToken();

      if (!idToken) {
        throw new Error('Não foi possível entrar com sua conta Google!');
      }

      const loginResponse = await LoginController.requestLoginGoogle(idToken);

      await LoginUtil.afterLogin(loginResponse);

      return true;
    } catch (err: any) {
      return this.handleLoginError(err);
    }
  }

  public static async loginWithLinkedIn(): Promise<boolean> {
    const authorizationCode = await LinkedInAuth.authenticate();

    try {
      const loginResponse = await LoginController.requestLoginLinkedIn(authorizationCode);
      await LoginUtil.afterLogin(loginResponse);

      return true;
    } catch (err: any) {
      return this.handleLoginError(err);
    }
  }

  private static handleLoginError(
    err: any,
  ): boolean {
    const operNotFound = err?.response?.data?.errorCode === 1;

    if (operNotFound) {
      return false;
    }

    let message = '';

    if (err.response) {
      message = LoginErrors.getErrorMessage(err.response.data.errorCode);
    } else {
      message = err.message;
    }

    if (!err.error) {
      Vue.$toast.error(message);
    }

    throw err;
  }

  public static async afterLogin(
    loginResponse: any,
    onExpired: () => void = () => null,
  ): Promise<void> {
    const userData = loginResponse.dataset.userData;

    if (!userData) {
      throw new Error('UserData inválido!');
    }

    if (this.isLoginExpired(loginResponse)) {
      Vue.$toast.warning(Messages.LOGIN_EXPIRED);
      onExpired();
      return;
    }

    const sysRes: any = await LoginController.requestStartSession(userData.USER, userData.TOKEN);

    LoginUtil.setLoginItemsOnStorage(sysRes);
    window.location.reload();
  }

  public static resetTokenAndReload() {
    localStorage.clear();
    window.location.reload();
  }

  public static isLoginExpired(loginResponse: any): boolean {
    return loginResponse?.dataset?.userData?.EXPIRED_PASSWORD === LoginUtil.LOGIN_EXPIRED_MESSAGE;
  }
}

export default LoginUtil;
