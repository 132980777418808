import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';

interface OperadorAcesso {
  NMOPERADOR: string;
  CPF: string;
  DSEMAILOPER: string;
  PASSWORD: string;
  PASSWORDCONFIRMATION: string;
  DTNASC: string;
  IDSEXOPESSOA: string;
  CDESTACIVIL: string;
  CDPAIS: string;
  NRGRAUINSTR: string;
  IDPOSSUIFILHO: string;
  CDPAISENDERECO: string;
  SGESTADOENDERECO: string;
  CDMUNICIPIOENDERECO: string;
}

class SingupController {
  public static async setOperadorAcesso(operador: OperadorAcesso): Promise<boolean> {
    const data = {
      requestType: 'Row',
      row: {
        ...operador,
      },
    };

    const response = await HttpRequest.post('/setOperadorAcesso', data, false);
    return response?.data?.dataset?.data[0];
  }
}

export default SingupController;
