import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import LoginUtil from '@/assets/ts/utils/LoginUtil';

@Component({
  name: 'SecurityArea',
  components: {},
})
class SecurityArea extends Vue {
  @Prop({ default: false })
  private readonly dialog: boolean;

  private panels: any = [];

  private loading = false;

  private row = {
    ACTUAL_PASSWORD: '',
    NEW_PASSWORD: '',
    NEW_PASSWORD_CONFIRMATION: '',
  };

  private async securityAreaOnClick(): Promise<void> {
    const row = this.row;
    if (!row.ACTUAL_PASSWORD || !row.NEW_PASSWORD || !row.NEW_PASSWORD_CONFIRMATION) {
      this.$toast.warning('Atenção! Preencha todos os campos para prosseguir!');
      return;
    }

    if (row.NEW_PASSWORD !== row.NEW_PASSWORD_CONFIRMATION) {
      this.$toast.warning('Atenção! A nova senha e confirmação não correspondem!');
      return;
    }

    this.loading = true;
    const success = await LoginUtil.updatePassword(row.ACTUAL_PASSWORD, row.NEW_PASSWORD);
    this.loading = false;
    if (success) {
      this.close();
    }
  }

  @Emit('close')
  private close(): void {
    this.panels = [];
  }
}

export default SecurityArea;
