import Vue from 'vue';

class ConfirmationUtil {
  public static deniedAccess(callback: () => void) {
    (Vue as any).$confirm(
      {
        title: 'Alerta',
        message: 'Seu login não é válido. Entre em contato com os responsáveis para mais detalhes.',
        button: {
          yes: 'Ok',
        },
        callback,
      },
    );
  }
}

export default ConfirmationUtil;
