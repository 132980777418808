























import { Vue, Component } from 'vue-property-decorator';
import Header from '@/components/Header/Header.vue';
import BreakpointsHelper from './components/BreakpointsHelper.vue';
import ThemeUtil from './assets/ts/utils/ThemeUtil';
import SecurityArea from '@/components/SecurityArea/SecurityArea.vue';

@Component({
  name: 'App',
  components: {
    Header,
    BreakpointsHelper,
    SecurityArea,
  },
})
class App extends Vue {
  // public showHeader = true;
  public showSecurityArea = false;

  public get showHeader(): boolean {
    return this.$route.path !== '/login';
  }

  public created(): void {
    (window as any).app = this;
    ThemeUtil.setThemeByLocalStorage();
  }

  private toggleSecurityAreaVisibility() {
    this.showSecurityArea = !this.showSecurityArea;
  }
}

export default App;
