import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import LoginUtil from '@/assets/ts/utils/LoginUtil';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import Messages from '@/assets/ts/utils/Messages';

@Component({
  name: 'RedefinePassword',
  components: {},
})
class RedefinePassword extends Vue {
  // #region [ METHODS ]
  @Prop({ default: null })
  private defaultRow: { email: string, actualPassword: string };
  // #endregion

  // #region [ DATA ]
  private email = '';
  private actualPassword = '';
  private newPassword = '';
  private confirmNewPassword = '';
  private loading = false;
  // #endregion

  // #region [ LIFECYCLE ]
  private mounted() {
    this.email = this.defaultRow.email;
    this.actualPassword = this.defaultRow.actualPassword;
  }
  // #endregion

  // #region [ EVENTS ]
  @Emit('back')
  private emitBack(
    email: string = null,
    password: string = null,
  ): { email: string, password: string } {
    return { email, password };
  }
  // #endregion

  // #region [ METHODS ]
  public goBack(): void {
    this.emitBack();
  }

  public async setNewPassword(): Promise<void> {
    if (this.isInputNotValid()) {
      return;
    }

    try {
      this.loading = true;
      const isPasswordReset = await LoginUtil.updatePassword(
        this.actualPassword,
        this.newPassword,
        this.email,
      );

      if (isPasswordReset) {
        setTimeout(() => {
          this.emitBack(this.email, this.newPassword);
        }, 1500);
      }
    } finally {
      this.loading = false;
    }
  }

  public isInputNotValid(): boolean {
    const isPasswordNotFilled = Util.isEmptyOrBlank(this.newPassword) ||
      Util.isEmptyOrBlank(this.confirmNewPassword);

    const passwordAndConfirmationDoNotMatch = this.newPassword !== this.confirmNewPassword;

    if (isPasswordNotFilled) {
      Vue.$toast.warning(Messages.FILL_ALL_FIELDS);
    }

    if (passwordAndConfirmationDoNotMatch) {
      Vue.$toast.warning(Messages.PASSWORD_MISMATCH);
    }

    return isPasswordNotFilled || passwordAndConfirmationDoNotMatch;
  }
  // #endregion
}

export default RedefinePassword;
