





















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SnackbarUtils from '../../assets/utils/SnackbarUtils';
import ToolbarAction from '../../assets/interfaces/ToolbarAction';

@Component({
  name: 'TkToolbar',
  components: {},
})
class TkToolbar extends Vue {
  @Prop({ default: (): any  => [], required: false })
  private actions: (ToolbarAction | string)[];

  @Prop({ default: (): any  => [], required: false })
  private buttons: ToolbarAction[] ;

  private readonly detailsClickedEventName = 'detailsActionClicked';

  private readonly actionCickEvent = 'click:action';

  private defaultActions: ToolbarAction[] = [
    {
      name: 'baixar',
      icon: 'mdi-content-save',
      label: 'Baixar',
      showLabel: true,
      onClick: () => {},
    },
    {
      name: 'imprimir',
      icon: 'mdi-printer',
      label: 'Imprimir',
      showLabel: true,
      onClick: () => {},
    },
    {
      name: 'alinhar',
      icon: 'mdi-format-line-spacing',
      label: 'Alinha',
      showLabel: true,
      onClick: () => {},
    },
    {
      name: 'refresh',
      icon: 'mdi-refresh',
      label: 'Refresh',
      showLabel: true,
      onClick: () => {},
    },
    {
      name: 'error',
      icon: 'mdi-alert-circle',
      label: 'ERRO',
      showLabel: true,
      onClick: () => {},
    },
  ];

  // COMPUTED
  private get internalActions(): ToolbarAction[] {
    let actions = this.actions.map((action: ToolbarAction | string) => {
      const tbAction = typeof action === 'string' ?
        this.findDefaultAction(action) :
        action;

      return tbAction;
    });

    actions = actions.filter((action) => {
      return action.condition !== false;
    });

    return actions;
  }

  private get detailsActions(): ToolbarAction[] {
    const actions = this.internalActions.filter((action) => {
      const showInMenu = action.inMenu;

      return showInMenu;
    });

    return actions;
  }

  private get mainActions(): ToolbarAction[] {
    const actions = this.internalActions.filter((action) => {
      const showInMenu = action.inMenu;

      return !showInMenu;
    });

    return actions;
  }

  // METHODS
  private onClickAction(action: ToolbarAction) {
    if (action.onClick) {
      action.onClick();
    }
    this.emitClick(this.actionCickEvent, action);
  }

  private emitClick(event: string, value: any = null) {
    this.$emit(event, value);
  }

  private findDefaultAction(actionName: string): ToolbarAction {
    let action = this.defaultActions.find((defaultAction) => {
      const isAction = defaultAction.name === actionName;

      return isAction;
    });

    if (!action) {
      const message = `A action "${actionName}" não existe.`;
      SnackbarUtils.showMessage(message, 'error');

      action = this.findDefaultAction('error');
    }

    return action;
  }
}

export default TkToolbar;
