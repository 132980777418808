import Vue from 'vue';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import StatusCadastro from '../interfaces/dashboard/StatusCadastro';
import VagasRelacionadas from '../interfaces/dashboard/VagasRelacionadas';
import UltimaAtualizacao from '../interfaces/dashboard/UltimaAtualizacao';

class DashboardController {
  public static async getStatusCadastro(
    showLoading = true,
  ): Promise<StatusCadastro[]> {
    const route = 'getStatusCadastro';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, showLoading);
      ExpectedError.throwResponseError(response);

      const status: StatusCadastro[] = response.data.dataset.data;
      return status;
    } catch (err) {
      let message = 'Não foi possível carregar o status de cadastro!';

      if (err instanceof Error) {
        message = ExpectedError.getResponseErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getQtVagasRelacionadas(
    showLoading = true,
  ): Promise<VagasRelacionadas> {
    const route = 'getQtVagasRelacionadas';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, showLoading);
      ExpectedError.throwResponseError(response);

      const status: VagasRelacionadas = response.data.dataset.data;
      return status;
    } catch (err) {
      let message = 'Não foi possível carregar a quantidade de vagas!';

      if (err instanceof Error) {
        message = ExpectedError.getResponseErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getUltimaAtualizacao(
    showLoading = true,
  ): Promise<any> {
    const route = 'getUltimaAtualizacao';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, showLoading);
      ExpectedError.throwResponseError(response);

      const ultAtu: UltimaAtualizacao = response.data.dataset.data;

      if (Util.isEmptyOrBlank(ultAtu)) {
        return null;
      }

      return ultAtu;
    } catch (err) {
      let message = 'Não foi possível carregar a data da última atualização!';

      if (err instanceof Error) {
        message = ExpectedError.getResponseErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }
}

export default DashboardController;
