import MimeTypes from 'mime-types';
import download from 'downloadjs';
import b64ToBlob from 'b64-to-blob';
import JSZip from 'jszip';
import fileType from 'file-type';

import FileData from '../interfaces/FileData';
import Dictionary from '../interfaces/Dictionary';

class FileUtils {
  public static readonly TYPE_PDF = 'pdf';
  public static readonly TYPE_IMAGE = 'image';
  public static readonly TYPE_DOCUMENT = 'document';
  public static readonly TYPE_EXCEL = 'excel';
  public static readonly TYPE_PPT = 'ppt';
  public static readonly TYPE_COMPRESSED = 'compressed';
  public static readonly TYPE_OTHER = 'other';

  public static downloadBase64File(
    fileName: string,
    base64: string,
    mimeType: string
  ): Promise<void> {
    const platform: string = (window as any).device ?
      (window as any).device.platform :
      'browser';

    base64 = FileUtils.getIncompleteBase64(base64);
    const blobFile = b64ToBlob(base64, mimeType);

    if (!fileName.includes('.')) {
      fileName = this.getFileFullName(fileName, mimeType);
    }

    if (platform === 'browser') {
      return this.downloadFile(blobFile, fileName, mimeType);
    }

    const path = platform === 'android' ?
      'file:///storage/emulated/0/Download' :
      (window as any).cordova.documentsDirectory;

    return this.saveBlobInInternalStorage(fileName, blobFile, path);
  }

  public static downloadFile (
    data: string | Blob | File | Uint8Array,
    filename?: string,
    mimeType?: string
  ): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      const downloadRequest = download(data, filename, mimeType);

      if (downloadRequest === true) {
        resolve();
        return;
      }

      if (downloadRequest === false) {
        reject();
        return;
      }

      downloadRequest.addEventListener('load', () => {
        resolve();
      });

      downloadRequest.addEventListener('error', () => {
        reject();
      });

      downloadRequest.addEventListener('timeout', () => {
        reject();
      });
    });

    return promise;
  }

  public static saveBlobInInternalStorage(
    fileName: string,
    blobFile: Blob,
    path: string,
  ): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(path, (dir: any) => {
        dir.getFile(fileName, {create:true}, (file: any) => {
          file.createWriter((fileWriter: any) => {
            fileWriter.write(blobFile, () => {
              resolve();
            });
          }, () => {
            reject();
          });
        });
      });
    });

    return promise;
  }

  public static toBase64(file: File): Promise<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

    return promise;
  }

  public static mimeTypeToExtension(mimeType: string): string | boolean {
    const extension = MimeTypes.extension(mimeType);

    return extension;
  }

  public static async createAndDownloadZip(
    zipName: string,
    files: FileData[]
  ): Promise<void> {
    const zip = new JSZip();

    files.forEach((file) => {
      const blobFile = b64ToBlob(file.base64, file.type);
      const fileFullName = this.getFileFullName(file.name, file.type);

      zip.file(fileFullName, blobFile);
    });

    const zipBlob = await zip.generateAsync({type: 'blob'});

    download(zipBlob, zipName);
  }

  public static getFileFullName(
    fileName:string,
    mimeType: string
  ): string {
    const extension = this.mimeTypeToExtension(mimeType);
    const fullName = `${fileName}.${extension}`;

    return fullName;
  }

  public static getFullBase64(
    base64: string,
    mimeType: string,
  ): string {
    const isFull = base64.startsWith('data:');

    if (isFull) {
      return base64;
    }

    const fullBase64 = `data:${mimeType};base64,${base64}`;

    return fullBase64;
  }

  public static async getBase64MimeType(
    base64: string
  ) {
    const arrayBuffer = this.base64ToArrayBuffer(base64);
    const type = (await fileType.fromBuffer(arrayBuffer)).mime;

    return type;
  }

  public static base64ToArrayBuffer(
    base64: string,
  ): ArrayBuffer {
    const binaryString = window.atob(base64);
    const length = binaryString.length;

    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  public static decodeBase64(
    base64: string,
  ): string {
    const incompleteBase64 = this.getIncompleteBase64(base64);
    const decodedBase64 = atob(incompleteBase64);

    return decodedBase64;
  }

  public static getIncompleteBase64(
    base64: string
  ): string {
    const isFull = base64.startsWith('data:');

    if (!isFull) {
      return base64;
    }

    const splittedString = base64.split(';');

    let newBase64 = '';

    try {
      newBase64 = splittedString[1].split(',')[1];
    } catch {
      throw new Error('A string não está devidamente formatada!');
    }

    return newBase64;
  }

  public static getCompleteBase64(
    base64: string,
    type: string,
  ): string {
    const isFull = base64.startsWith('data:');

    if (isFull) {
      return base64;
    }

    const complete = `data:${type};base64,${base64}`;
    return complete;
  }

  public static getFileType(fileName: string): string {
    const fileExtension = fileName.split('.')[1];

    const extensionsMap: Dictionary<string[]> = {
      [this.TYPE_IMAGE]: [
        'png',
        'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi',
        'tiff', 'tif',
        'gif',
        'psd',
        'raw', 'arw', 'cr2', 'nrw', 'k25',
        'webp',
        'bmp', 'dib',
        'heif', 'heic',
        'indd', 'ind', 'indt',
        'jp2', 'j2k', 'jpf', 'jpm', 'mj2',
        'svg', 'svgz',
        'ai',
        'eps',
      ],
      [this.TYPE_PDF]: [
        'pdf',
      ],
      [this.TYPE_DOCUMENT]: [
        'doc', 'docx', 'docm',
        'dot', 'dotm', 'dotx',
        'odt',
        'wps',
        'xml',
        'xps',
        'txt',
        'htm', 'html',
      ],
      [this.TYPE_EXCEL]: [
        'xlsx', 'xlsm', 'xlsb',
        'xltx', 'xltm',
        'xls', 'xlt', 'xml',
        'xlam', 'xla', 'xlw',
        'xlr',
      ],
      [this.TYPE_PPT]: [
        'ppt', 'pptx',
        'odp',
      ],
      [this.TYPE_COMPRESSED]: [
        'rar',
        'zip',
        '7z',
        'gz',
        'z',
      ],
    };

    const types = Object.keys(extensionsMap);

    const fileType = types.reduce((accumulator, key) => {
      const typeExtensions = extensionsMap[key];
      const fileIsFromType = typeExtensions.includes(fileExtension);

      return fileIsFromType ? key : accumulator;
    }, this.TYPE_OTHER);

    return fileType;
  }
}

(window as any).FileUtils = FileUtils;

export default FileUtils;
