import Field from '../interfaces/Field';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import ExpectedError from './ExpectedError';
import EditableTableColumn from '../interfaces/EditableTableColumn';
import CommonRelation from '../interfaces/CommonRelation';
import Dictionary from '../interfaces/Dictionary';
import queryString from 'query-string';

class Util {
  public static indexOf(array: any[], condition: any): number {
    const indexes = Util.indexesOf(array, condition);

    // comparação com undefined é necessária já que 0 é "traduzido" como false
    const index = indexes[0] === undefined ?
      -1 :
      indexes[0];

    return index;
  }

  public static indexesOf(array: any[], condition: any): number[] {
    const indexes = array.reduce((accumulator, item, index) => {
      if (condition(item)) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    return indexes;
  }

  public static htmlFind(
    collection: HTMLCollection,
    findFunction: any,
  ): HTMLElement {
    const collectionArray = Array.from(collection);

    const foundElement = collectionArray.find(findFunction);

    return foundElement as HTMLElement;
  }

  public static deepCopy<T>(objectToCopy: T): T {
    let copy = _.cloneDeep(objectToCopy);

    return copy;
  }

  public static arrayRemove(
    array: any[],
    element: any,
    functional = true
  ) {
    let indexToRemove = -1;
    const arrayCopy = [...array];

    arrayCopy.forEach((item, i) => {
      if (item === element) {
        indexToRemove = i;
      }
    });

    if (indexToRemove !== -1) {
      if (functional) {
        arrayCopy.splice(indexToRemove, 1);
      } else {
        array.splice(indexToRemove, 1);
      }
    }

    return arrayCopy;
  }

  public static isUndefined(value: any): boolean {
    return typeof (value) === 'undefined';
  }

  public static isNull(value: any): boolean {
    return value === null;
  }

  public static isUndefinedOrNull(value: any): boolean {
    return Util.isUndefined(value) || Util.isNull(value);
  }

  public static isBlank(value: any): boolean {
    return value.toString().trim().length === 0;
  }

  public static isEmptyOrBlank(value: any): boolean {
    const emptyBlank =
      Util.isUndefinedOrNull(value) ||
      Util.isBlank(value)           ||
      Util.objectIsEmpty(value);

    return emptyBlank;
  }

  public static emptyArray(array: any[]): any[] {
    array.splice(0, array.length);

    return array;
  }

  public static redefineArray(oldArray: any[], newArray: any[]) {
    Util.emptyArray(oldArray);
    oldArray.push(...newArray);

    return oldArray;
  }

  public static arrayRemoveCondition<T>(
    array: T[],
    condition: (item: T) => boolean,
    functional = false
  ) {
    const indexes = Util.indexesOf(array, condition);
    const arrayCopy = [...array];

    // Sorts the array in descending order
    indexes.sort((a, b) => b - a);
    indexes.forEach(index => arrayCopy.splice(index, 1));

    if (functional) {
      return arrayCopy;
    }

    Util.redefineArray(array, arrayCopy);

    return array;
  }

  public static copyObject(destination: any, source: any): any {
    Object.keys(destination).forEach(key => {
      delete destination[key];
    });

    Object.keys(source).forEach(key => {
      destination[key] = source[key];
    });

    return destination;
  }

  public static replaceItem<T = any>(
    newItem: T,
    array: T[],
    condition: (item: T) => boolean,
    functional = false
  ): T[] {
    const arrayCopy = Util.deepCopy(array);
    const newItemCopy = Util.deepCopy(newItem);
    const itemIndex = Util.indexOf(array, condition);

    const arr = functional ?
      arrayCopy :
      array;

    if (itemIndex !== -1) {
      arr.splice(itemIndex, 1, newItemCopy);
    }

    return arr;
  }

  public static arrayIncludes(array: any[], condition: (item: any) => boolean): boolean {
    const item = array.find(condition);
    const includes = item !== undefined;

    return includes;
  }

  public static verifyRequired(currentRow: any, columns: any) {
    let valido = true;
    columns.forEach((column: any)=> {
      if(column.required){
        if(!currentRow[column.value]){
          valido = false;
        }
      }
    });
    return valido;

  }

  public static verifyFormRequired(row: any, fields: Field[]) {
    const isValid = fields.reduce((accumulator, field) => {
      let fieldIsValid = true;

      if (field.required) {
        const value = row[field.name];
        fieldIsValid = !!value;
      }

      return accumulator && fieldIsValid;
    }, true);

    return isValid;
  }

  public static deepCompare(item1: any, item2: any): boolean {
    let areEqual: boolean;

    if (typeof item1 === 'object' && typeof item2 === 'object') {
      areEqual = JSON.stringify(item1) === JSON.stringify(item2);
    } else {
      areEqual = item1 === item2;
    }

    return areEqual;
  }

  public static objectIsEmpty(obj: Object): boolean {
    if (typeof obj !== 'object') {
      return false;
    }

    // if File
    if (obj instanceof File) {
      return false;
    }

    const keys = Object.keys(obj);
    const empty = keys.length === 0;

    return empty;
  }

  public static arrayIntersection(array1: any[], array2: any[]) {
    return array1.filter(value => array2.includes(value));
  }

  public static replaceAll(str: string, search: string, replacer: string): string {
    let newString = str;

    while (newString.search(search) !== -1) {
      newString = newString.replace(search, replacer);
    }

    return newString;
  }

  public static groupBy(
    array: any[],
    property: string
  ): any {
    const groupedArray: any = {};

    array.forEach((item) => {
      const value = item[property];
      const grouperIsRegistered = groupedArray[value] !== undefined;

      if (!grouperIsRegistered) {
        groupedArray[value] = [];
      }

      groupedArray[value].push(item);
    });

    return groupedArray;
  }

  public static getErrorMessage(
    response: AxiosResponse,
  ): string | undefined {
    let errorMessage: string | undefined = undefined;

    if (response.data.messages) {
      errorMessage = response.data.messages[0].message;
    } else if (typeof response.data === 'string') {
      errorMessage = response.data;
    }

    if (errorMessage !== undefined) {
      errorMessage = errorMessage.replace('(HCMSERVICES) ', '');
    }

    return errorMessage;
  }

  public static throwErrorMessage(
    response: AxiosResponse,
  ) {
    const errorMessage = this.getErrorMessage(response);

    if (errorMessage !== undefined) {
      throw new Error(errorMessage);
    }
  }

  public static arrayIncludesArrayItems(
    array: any[],
    searchArray: any[],
  ): boolean {
    const includesArray = searchArray.reduce((accumulator: boolean, searchItem: any) => {
      const includesItem = array.includes(searchItem);
      return includesItem && accumulator;
    }, true);

    return includesArray;
  }

  public static union(
    alpha: any[],
    beta: any[],
  ): any[] {
    const union = [
      ...alpha,
      ...beta
    ];

    return union;
  }

  public static intersection(
    alpha: any[],
    beta: any[],
  ): any[] {
    const intersection: any[] = [];

    beta.forEach((betaItem: any) => {
      if (alpha.includes(betaItem)) {
        intersection.push(betaItem);
      }
    });

    return intersection;
  }

  public static difference(
    alpha: any[],
    beta: any[],
  ): any[] {
    const union = this.union(alpha, beta);
    const intersection = this.intersection(alpha, beta);

    const difference: any[] = [];

    union.forEach((item: any) => {
      const includes = intersection.includes(item);

      if (!includes) {
        difference.push(item);
      }
    });

    return difference;
  }

  public static differenceLeft(
    alpha: any[],
    beta: any[],
  ): any[] {
    const difference = this.difference(alpha, beta);

    const differenceLeft: any[] = [];

    alpha.forEach((alphaItem: any) => {
      const includes = difference.includes(alphaItem);

      if (includes) {
        differenceLeft.push(alphaItem);
      }
    });

    return differenceLeft;
  }

  public static differenceRight(
    alpha: any[],
    beta: any[],
  ): any[] {
    const differenceRight = this.differenceLeft(beta, alpha);

    return differenceRight;
  }

  public static findChildRecursive(
    element: Element,
    identifier: string | ((element: Element) => boolean),
  ): Element {
    const children = Array.from(element.children);

    for (let child of children) {
      const matches = typeof identifier === 'string' ?
        Util.matchesIdentifier(child, identifier) :
        identifier(element);

      if (matches) {
        return child;
      }

      const find = Util.findChildRecursive(child, identifier);

      if (find) {
        return find;
      }
    }

    return undefined;
  }

  public static matchesIdentifier(
    element: Element,
    identifier: string,
  ): boolean {
    if (identifier.includes('.')) {
      const className = identifier.replace('.', '');
      const matches = element.classList.contains(className);

      return matches;
    }

    if (identifier.includes('#')) {
      const id = identifier.replace('#', '');
      const matches = element.id === id;

      return matches;
    }

    const tag = identifier;
    const matches = element.tagName === tag.toUpperCase();

    return matches;
  }

  public static setTimeout(
    msTime: number
  ): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, msTime);
    });

    return promise;
  }

  public static arrayToString(
    array: string[],
  ): string {
    const text = array.reduce((accumulator, char) => {
      return accumulator + char;
    }, '');

    return text;
  }

  public static initCap(
    text: string,
  ): string {
    const textArray = text.split('');

    textArray.forEach((char, index) => {
      const lastChar = textArray[index - 1];

      textArray[index] = index === 0 || lastChar === ' ' ?
        char.toUpperCase() :
        char.toLowerCase();
    });

    const capitalizedText = Util.arrayToString(textArray);

    return capitalizedText;
  }

  public static findByKey(
    array: any[],
    key: string,
    value: any,
  ): any {
    const result = array.find((item) => {
      return item[key] === value;
    });

    return result;
  }

  public static getDescriptionValue(
    field: Field,
    row: any,
  ): string {
    const { descriptionField, name } = field;
    const value = row[name];

    const selectedItem = field.items.find((item) => {
      return item[name] === value;
    });

    if (!selectedItem) {
      return undefined;
    }

    const description = selectedItem[descriptionField];

    return description;
  }

  public static setDescriptionField(
    field: Field,
    row: any,
  ) {
    const { descriptionField } = field;
    const description = Util.getDescriptionValue(field, row);

    row[descriptionField] = description;
  }

  public static findField(
    fields: Field[],
    fieldName: string,
  ): Field {
    const field = fields.find((f) => f.name === fieldName);
    return field;
  }

  public static allTrue(...args: any[]) {
    const isTrue = args.reduce((accumulator: boolean, arg) => {
      return accumulator && !!arg;
    }, true);

    return isTrue;
  }

  public static oneTrue(...args: any[]) {
    const isTrue = args.reduce((accumulator: boolean, arg) => {
      return accumulator || !!arg;
    }, false);

    return isTrue;
  }

  public static valueOrUndefined(
    trueValue: any,
    value: any
  ): boolean {
    const isTrue =
      value === trueValue ||
      value === undefined;

    return isTrue;
  }

  public static dateAndTimeToDate(
    dateTime: string
  ): string {
    if (!dateTime || typeof dateTime !== 'string') {
      return dateTime;
    }

    // "23/11/2020 09:00:00" => ["23/11/2020", "09:00:00"]
    const [date] = dateTime.split(' ');

    return date;
  }

  public static getFormEmptyRequiredFields(
    row: any,
    fields: Field[],
  ): Field[] {
    const requiredFields = fields.filter((field) => {
      const isRequired =
        field.required === true &&
        field.isVisible !== false;

      return isRequired;
    });

    const emptyRequiredFields = requiredFields.filter((field) => {
      const value = row[field.name];
      return !value;
    });

    return emptyRequiredFields;
  }

  public static findSelectedItem(field: Field | EditableTableColumn, value: any) {
    const selectedItem = field.items.find((item: any) => {
      const { valueField } = field;
      const isSelected = item[valueField] === value;

      return isSelected;
    });

    return selectedItem;
  }

  public static getGridNextId(
    gridData: any,
  ) {
    if (gridData.currentId === undefined) {
      gridData.currentId = 0;
    }

    return ++gridData.currentId;
  }

  /**
   *  Function used to replace all ocurrencies of an item in object
   * 
   * @param itemsArray 
   * @param itemToBeReplaced 
   * @param itemReplace 
   * @param checkReplaceCondition 
   */
  public static arrayReplace(itemObject: any[], itemToBeReplaced: any, itemReplace: any) {
    const objectCopy = { ...itemObject };
    for (const itemIndex in objectCopy) {
      objectCopy[itemIndex] =
        objectCopy[itemIndex] === itemToBeReplaced ? itemReplace : objectCopy[itemIndex]
    }

    return objectCopy;
  }

  /**
   * Returns true if at least one of the items is true.
   */
  public static anyTrue(arr: any[]): boolean {
    const anyTrue = arr.reduce((accumulator, item) => {
      return accumulator || item;
    }, false);

    return anyTrue;
  }

  public static findRow(
    rows: any[],
    row: any
  ): any {
    const foundRow = rows.find((_row) => {
      return row.__id === _row.__id;
    });

    return foundRow;
  }

  public static countSubstring(
    str: string,
    subStr: string,
  ): number {
    const regex = new RegExp(subStr, 'g');
    const count = (str.match(regex) || []).length;

    return count;
  }

  public static hasLetter(
    str: string,
  ): boolean {
    const hasLetter = str.search(/.*[a-zA-Z].*/) !== -1;
    return hasLetter;
  }

  public static isObject(object: any) {
    return typeof object === 'object' && object !== null;
  }

  public static betterGroupBy(
    collection: any[],
    keys: string[],
  ): any[] {
    const groupsItems = Object.values(_.groupBy(collection, (item: any) => {
      const keysValues = keys.reduce((accumulator, key) => {
        accumulator[key] = item[key];
        return accumulator;
      }, {} as any);

      const keysValuesJson = JSON.stringify(keysValues);
      return keysValuesJson;
    }));

    const groups = groupsItems.map((groupItems: any[]) => {
      const group: any = {
        _items: groupItems,
      };

      keys.forEach((key) => {
        group[key] = groupItems[0][key];
      });

      return group;
    });

    return groups;
  }

  public static reverseString(str: string): string {
    return str.split('').reverse().join('');
  }

  public static paramsToQuery(params: CommonRelation): string {
    let query = queryString.stringify(params);

    if (query) {
      query = '?' + query;
    }

    return query;
  }

  public static replace<T>(
    array: T[],
    oldItem: T,
    newItem: T,
    functional = false,
  ): T[] {
    const list = functional ? [...array] : array;
    const index = list.indexOf(oldItem);

    if (index !== -1) {
      list[index] = newItem;
    }

    return list;
  }
}

export default Util;
