interface ErrosRelations {
  [index: number]: string;
}

class LoginErrors {
  public static readonly DEFAULT_ERROR_MESSAGE =
    'Não foi possível realizar o login. Favor tentar novamente mais tarde.';

  public static readonly ERRORS_MESSAGES: ErrosRelations = {
    1: 'Usuário não encontrado!',
    2: 'Usuário inativado!',
    5: 'Email/senha incorretos!',
    6: 'Acesso negado!',
    9: 'Credenciais inválidas! (AD)',
    10: 'Não conectado! (AD)',
    11: 'Senha desatualizada! (AD)',
    12: 'Usuário não encontrado! (AD)',
    14: 'Usuário sem organização!',
    16: 'ID do produto não encontrado!',
    18: 'Não use localhost!',
    19: 'A URL requisitada não foi encontrada!',
    20: 'Não foi possível encontrar as informações do usuário!',
    21: 'Falha ao descriptografae acesso da organização!',
    22: 'Produto não está disponível!',
    23: 'Itens do menu não foram encontrados!',
    24: 'Data do produto expirou!',
    25: 'O IP está fora do alcance!',
    26: 'Usuário não possui perfil de acesso!',
    27: 'Domínio inválido!',
    28: 'Produto não autorizado!',
    29: 'Caso erre a senha novamente, o usuário será bloqueado!',
    30: 'Acesso bloqueado!',
    31: 'Número máximo de tentativas excedido. O usuário foi bloqueado!',
    32: 'Email/senha incorretos!',
    33: 'Senha não reutilizada!',
    34: 'Endereço MAC incorreto!',
    50: 'Falha ao requisitar os dados do usuário!',
  };

  public static getErrorMessage(
    errorCode: number,
  ): string {
    const message =
      LoginErrors.ERRORS_MESSAGES[errorCode] ||
      LoginErrors.DEFAULT_ERROR_MESSAGE;

    return message;
  }
}

export default LoginErrors;
