import { Vue, Component } from 'vue-property-decorator';
import LoginSignUp from '@/components/LoginSignUp/LoginSignUp.vue';
import ResetPassword from '@/components/ResetPassword/ResetPassword.vue';
import RedefinePassword from '@/components/RedefinePassword/RedefinePassword.vue';
import ThemeUtil from '@/assets/ts/utils/ThemeUtil';
import LoginUtil from '@/assets/ts/utils/LoginUtil';
import GoogleAuth from '@/assets/ts/utils/GoogleAuth';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

@Component({
  name: 'Login',
  components: {
    LoginSignUp,
    ResetPassword,
    RedefinePassword,
  },
})
class Login extends Vue {
  // #region [ DATA ]
  private email = '';
  private password = '';
  private redefinePasswordRow = {};
  private tabs = {
    login: 0,
    signUp: 1,
    resetPassword: 2,
    redefinePassword: 3,
  };

  private labels = {
    signIn: {
      text: 'Entrar',
      default: 'Entrar',
      loading: 'Entrando...',
      toggleText: () => this.toggleSignInText(this.labels.signIn),
    },
    google: {
      text: 'Entrar com Google',
      default: 'Entrar com Google',
      loading: 'Entrando...',
    },
  }

  public tab = 0;

  public loading = {
    signIn: false,
    signUp: false,
    google: false,
    linkedIn: false,
  };
  // #endregion

  // #region [ COMPUTED ]
  public get isSignInTextLoading(): boolean {
    return this.labels.signIn.text === this.labels.signIn.loading;
  }

  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get labelGoogleBtn(): string {
    return this.loading.google ? this.labels.google.loading : this.labels.google.default;
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private created(): void {
    GoogleAuth.config();

    (window as any).login = this;
  }

  private mounted(): void {
  }
  // #endregion

  // #region [ METHODS ]
  public callback(): void {
    //
  }

  public signUp(): void {
    if (this.isLoading) {
      return;
    }

    this.tab = this.tabs.signUp;
  }

  public async signUpTabOnBack(data: { email: string, password: string } = null): Promise<void> {
    const defaultValues: any = { password: null, email: null };
    const { email, password } = !Util.isEmptyOrBlank(data) ? data : defaultValues;

    this.email = email;
    this.tab = this.tabs.login;
    this.password = '';

    if (!Util.isEmptyOrBlank(email) && !Util.isEmptyOrBlank(password)) {
      this.password = password;
      await this.login();
    }
  }

  public goToResetPassword(): void {
    this.tab = this.tabs.resetPassword;
  }

  public goToRedefinePassword(): void {
    this.redefinePasswordRow = { email: this.email, actualPassword: this.password };
    this.tab = this.tabs.redefinePassword;
  }

  public onLoginExpired(): void {
    return this.goToRedefinePassword();
  }

  public async login(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.loading.signIn = true;

    try {
      this.labels.signIn.toggleText();
      await LoginUtil.login(this.email, this.password, this.onLoginExpired);
    } finally {
      this.labels.signIn.toggleText();
      this.loading.signIn = false;
    }
  }

  private goToDashboard(): void {
    this.$router.push('/');
  }

  public toggleDark(): void {
    ThemeUtil.toggleDark();
  }

  public toggleSignInText(label: {[p: string]: any}): void {
    label.text = label.text === label.default ? label.loading : label.default;
  }

  public async loginWithGoogle(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    let operFound = true;

    this.loading.google = true;

    try {
      operFound = await LoginUtil.loginWithGoogle();
    } finally {
      this.loading.google = false;
    }

    if (!operFound) {
      Vue.$toast.info('Nenhum operador encontrado. Crie uma conta.');
      this.signUp();
    }
  }

  public async loginWithLinkedIn(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    let operFound = true;

    this.loading.linkedIn = true;

    try {
      operFound = await LoginUtil.loginWithLinkedIn();
    } finally {
      this.loading.linkedIn = false;
    }

    if (!operFound) {
      Vue.$toast.info('Nenhum operador encontrado. Crie uma conta.');
      this.signUp();
    }
  }
  // #endregion
}

export default Login;
