import { Vue, Component, Emit, Watch } from 'vue-property-decorator';
import FileUtils from '@/../bower_components/teknisavuecomponents/assets/utils/FileUtils';
import ThemeUtil from '@/assets/ts/utils/ThemeUtil';
import LoginUtil from '@/assets/ts/utils/LoginUtil';
import LoginController from '@/assets/ts/controller/LoginController';
import NovaFoto from '../NovaFoto/NovaFoto.vue';

@Component({
  name: 'Header',
  components: {
    NovaFoto,
  },
})
class Header extends Vue {
  private readonly DEFAULT_PROFILE_PICTURE = 'user.jpg';
  public profilePicture = '';

  public novaFotoPopup = {
    show: false,
  };

  public loadingPicture = false;

  public callback(): void {
    //
  }

  public goToMeuPerfil(): void {
    this.$router.push('/meu-perfil');
  }

  public signOut(): void {
    LoginUtil.logout();
  }

  @Emit('click:security')
  public securityAreaOnClick(): void {

  }

  @Watch('user', { immediate: true })
  private userOnChange(): void {
    const userIsSet = !!this.user?.USER_ID;
    this.profilePicture = this.DEFAULT_PROFILE_PICTURE;

    if (userIsSet) {
      this.loadProfilePicture();
    }
  }

  public get user(): any {
    return this.$store.getters.user;
  }

  public toggleDark(): void {
    ThemeUtil.toggleDark();
  }

  private async loadProfilePicture(): Promise<void> {
    this.loadingPicture = true;

    try {
      const file = await LoginController.getFoto();

      if (file) {
        const { base64, type } = file;
        const completeBase64 = FileUtils.getCompleteBase64(base64, type);

        this.profilePicture = base64 ? completeBase64 : this.DEFAULT_PROFILE_PICTURE;
      }
    } finally {
      this.loadingPicture = false;
    }
  }

  public novaFotoOnSave(): void {
    this.loadProfilePicture();
  }

  public openNovaFotoPopup(): void {
    this.novaFotoPopup.show = true;
  }
}

export default Header;
