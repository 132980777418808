import axios, { AxiosInstance, AxiosProxyConfig, AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { mapMutations } from 'vuex';
import Util from './Util';
import store from '../../../../src/store/store';
import SnackbarUtils from './SnackbarUtils';
import RequestParams from '../interfaces/RequestParams';
import Dictionary from '../interfaces/Dictionary';

interface ResponseInterceptor {
  onFulfilled?: (value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>;

  onRejected?: (error: any) => any;

  code: number;
}

interface RequestInterceptor {
  onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;

  onRejected?: (error: any) => any;

  code: number;
}




// Headers list
const requestHeaders = {
  oAuthToken: 'OAuth-Token',
};

// Default header for requests
if (localStorage.TOKEN) {
  axios.defaults.headers.common[requestHeaders.oAuthToken] = localStorage.TOKEN;
}

class HttpRequest {
  public static url: string = null;

  private static http: AxiosInstance = null;

  private static lastRequestCode = 0;

  private static responseInterceptors: ResponseInterceptor[] = [];
  private static requestInterceptors: RequestInterceptor[] = [];

  private static defaultConfig: AxiosRequestConfig = {
    // withCredentials: true,
  };

  private static setLoadingVisibility =
    mapMutations(['setLoadingVisibility']).setLoadingVisibility;

  private static requests: Request[] = [];

  public static setUrl(
    url: string,
  ) {
    this.url = url;

    this.http = axios.create({
      baseURL: url,
    });

    this.responseInterceptors.forEach((interceptor) => {
      this.http.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    });

    this.requestInterceptors.forEach((interceptor) => {
      this.http.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  public static get(
    route: string,
    params: any,
    showLoading: boolean = true,
    requestCode: number = null,
    removeBlankParams: boolean = true,
    config: AxiosRequestConfig = null,
  ) {
    const completeUrl = HttpRequest.createCompleteUrl(route, params, removeBlankParams);

    const cancelTokenSource = axios.CancelToken.source();

    const axiosConfig = config ?
      {...this.defaultConfig, ...config} :
      this.defaultConfig;

    axiosConfig.cancelToken = cancelTokenSource.token;

    const request = axios.get(
      completeUrl, axiosConfig
    );

    this.saveRequest(
      request,
      cancelTokenSource,
      showLoading,
      requestCode,
    );

    return request;
  }

  public static post(
    route: string,
    params: any,
    showLoading: boolean = true,
    requestCode: number = null,
    config: AxiosRequestConfig = null,
  ) {
    const cancelTokenSource = axios.CancelToken.source();

    const axiosConfig = config ?
      {...this.defaultConfig, ...config} :
      this.defaultConfig;

    axiosConfig.cancelToken = cancelTokenSource.token;

    const request = this.http.post(route, params, axiosConfig);

    this.saveRequest(
      request,
      cancelTokenSource,
      showLoading,
      requestCode,
    );

    return request;
  }

  public static put(route: string, params: any, showLoading: boolean = true) {
    const cancelTokenSource = axios.CancelToken.source();

    const request = axios.put(route, params, {
      cancelToken: cancelTokenSource.token,
    });

    this.saveRequest(
      request,
      cancelTokenSource,
      showLoading,
    );

    return request;
  }

  public static delete(route: string, params: any, showLoading: boolean = true) {
    const cancelTokenSource = axios.CancelToken.source();

    const request = axios.delete(route, {
      cancelToken: cancelTokenSource.token,
    });

    this.saveRequest(
      request,
      cancelTokenSource,
      showLoading,
    );

    return request;
  }

  public static addReponseInterceptor(
    onFulfilled?: (value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
    onRejected?: (error: any) => any,
  ): number {
    const interceptorNumber = this.http.interceptors.response.use(onFulfilled, onRejected);

    const interceptor: ResponseInterceptor = {
      onFulfilled,
      onRejected,
      code: interceptorNumber,
    };

    this.responseInterceptors.push(interceptor);

    return interceptorNumber;
  }

  public static addRequestInterceptor(
    onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any
  ): number {
    const interceptorNumber = this.http.interceptors.request.use(onFulfilled, onRejected);

    const interceptor: RequestInterceptor = {
      onFulfilled,
      onRejected,
      code: interceptorNumber,
    };

    this.requestInterceptors.push(interceptor);

    return interceptorNumber;
  }

  public static showLoading(request: Request) {
    if (!store.state.hasOwnProperty('loadingVisibility') && !store.state.hasOwnProperty('appLoading')) {
      return;
    }

    store.commit('setLoadingVisibility', true);

    request.promise.finally(() => {
      if (this.requestsShowLoading().length === 0) {
        store.commit('setLoadingVisibility', false);
      }
    });
  }

  public static fakeLoading(timeout: number) {
    const timeoutPromise = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });

    this.saveRequest(
      timeoutPromise,
      null,
      true,
    );

    return timeoutPromise;
  }

  public static createCompleteUrl(route: string, params: Dictionary<string|number>, removeBlankParams = true): string {
    const inlineQuery = HttpRequest.createInlineQuery(params, removeBlankParams);
    const completeUrl = `${HttpRequest.url}/${route}?${inlineQuery}`;

    return completeUrl;
  }

  public static createInlineQuery(parameters: Dictionary<string|number>, removeBlankParams = true): string {
    const paramsNames = Object.keys(parameters);

    const notEmptyParamsNames = paramsNames.filter((paramName: string) => {
      const isEmpty = Util.isEmptyOrBlank(parameters[paramName]);

      return !removeBlankParams || !isEmpty;
    });

    const paramsStrings = notEmptyParamsNames.map((paramName) => {
      const value = parameters[paramName];
      const queryParam  = `${paramName}=${value}`;

      return queryParam;
    });

    const queryParams = paramsStrings.join('&');

    return queryParams;
  }

  public static async request(
    params: RequestParams
  ) {
    params = this.parseParams(params);

    try {
      let response;

      if (params.method === 'GET') {
        response = await HttpRequest.get(params.route, params.params);
      } else {
        response = await HttpRequest.post(params.route, params);
      }

      if (response.data.messages) {
        const message = response.data.messages[0].message;
        SnackbarUtils.showMessage(message, SnackbarUtils.TYPE_ERROR);

        return undefined;
      }

      if (params.successMessage) {
        SnackbarUtils.showMessage(params.successMessage, SnackbarUtils.TYPE_SUCCESS);
      }

      const data = response.data.dataset[params.datasetName];

      return data;
    } catch (err) {
      if (params.errorMessage) {
        SnackbarUtils.showMessage(params.errorMessage, SnackbarUtils.TYPE_ERROR);
      }

      if (params.throwError) {
          throw err;
      }

        return undefined;
    }
  }

  private static parseParams(params: RequestParams) {
    const paramsCopy: RequestParams = Util.deepCopy(params);

    const defaultParams: any = {
      datasetName: 'data',
      showLoading: true,
      successMessage: undefined,
      errorMessage: undefined,
      throwError: true,
    }

    for (let key in defaultParams) {
      (paramsCopy as any)[key] =
        (paramsCopy as any)[key] ||
        defaultParams[key]
    }

    return paramsCopy;
  }

  private static requestsShowLoading(): Request[] {
    const requests = this.requests.filter((request) => {
      return request.showLoading === true;
    });

    return requests;
  }

  public static cancelRequest(
    requestCode: number,
  ) {
    const requestToCancel = this.requests.find((request) => {
      return request.requestCode === requestCode;
    });

    if (requestToCancel) {
      requestToCancel.cancelTokenSource.cancel('CANCELED');
    }
  }

  public static saveRequest (
    promise: Promise<any>,
    cancelTokenSource: CancelTokenSource,
    showLoading: boolean,
    requestCode: number = null,
  ) {
    if (requestCode === null) {
      requestCode = ++this.lastRequestCode;
    }

    const requestObject: Request = {
      promise,
      cancelTokenSource,
      showLoading,
      requestCode,
    };

    promise.finally(() => {
      Util.arrayRemove(HttpRequest.requests, requestObject, false);
    });

    this.requests.push(requestObject);

    if (showLoading) {
      HttpRequest.showLoading(requestObject);
    }
  }

  public static generateRequestCode(): number {
    return ++this.lastRequestCode;
  }

  public static getParamsFromQuery(
    query: string,
  ): Dictionary {
    if (query[0] === '?') {
      query = query.substring(1);
    }

    const queryParams = query.split('&');

    const params = queryParams.reduce((accumulator, queryParam) => {
      const [param, value] = queryParam.split('=');
      accumulator[param] = value;

      return accumulator;
    }, {} as Dictionary);

    return params;
  }
}

(window as any).HttpRequest = HttpRequest;

interface Request {
  cancelTokenSource: CancelTokenSource;
  promise: Promise<AxiosResponse | void>;
  requestCode: number;
  showLoading: boolean;
}

export default HttpRequest;