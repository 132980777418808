import Util from './Util';
import { format, isValid, differenceInDays, compareAsc } from 'date-fns';
import { DateInterval } from '../interfaces/interfaces';

class DateUtil {
  public static readonly PTBR_REGION = 'pt-BR';
  public static readonly dateIntervalSeparator = ' a ';
  public static readonly DATESTRINGLENGTH = 10;

  public static formatDate(date: string): string {
    let [day, month, year] = date.split('/');

    day = DateUtil.formatDay(day);
    month = DateUtil.formatMonth(month);
    year = DateUtil.formatYear(year);

    const adjustedDate = `${day}/${month}/${year}`;

    return adjustedDate;
  }

  public static formatDay(day: string): string {
    const currentDay = (new Date().getDay() + 1);

    const dayNumber = day ?
      parseInt(day, 10) :
      currentDay;

    const validDayNumber = Math.max(Math.min(dayNumber, 31), 1);
    const dayString = validDayNumber.toString().padStart(2, '0');

    return dayString;
  }

  public static formatMonth(month: string): string {
    const currentMonth = (new Date().getMonth() + 1);

    const monthNumber = month ?
      parseInt(month, 10) :
      currentMonth;

    const validMonthNumber = Math.max(Math.min(monthNumber, 12), 1);
    const monthString = validMonthNumber.toString().padStart(2, '0');

    return monthString;
  }

  public static formatYear(year: string): string {
    const currentYear = (new Date().getFullYear());

    const yearNumber = year ?
      parseInt(year, 10) :
      currentYear;

    const validYearNumber = Math.max(yearNumber, 0);
    const yearString = validYearNumber.toString();

    return yearString;
  }

  /**
   * Checks whether a date in the DD/MM/YYYY format is valid.
   * @param dateString A date string in the DD/MM/YYYY format.
   */
  public static isValid(dateString: string): boolean {
    if (!dateString) {
      return false;
    }

    const [day, month, year] = dateString.split('/');

    if (!day || !month || !year) {
      return false;
    }

    const formattedDate = DateUtil.formatToYYYYMMDD(dateString);
    const date = new Date(formattedDate);
    const dateIsValid = isValid(date);

    return dateIsValid;
  }

  public static formatToYYYYMMDD(dateString: string): string {
    if (!dateString) {
      return null;
    }

    const [day, month, year] = dateString.split('/');
    const formatedDateString = `${year}-${month}-${day}`;

    return formatedDateString;
  }

  public static formatToDDMMYYYY(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    const formatedDateString = `${day}/${month}/${year}`;

    return formatedDateString;
  }

  // value format: YYYY-MM
  public static formatDateFromYYYYMM(value: string): string {
    const dateSplitted = value.split('-');
    return `01/${dateSplitted[1]}/${dateSplitted[0]}`;
  }

  // value format DD/MM/YY
  public static formatDateToYYYYMM(value: string): string {
    const dateSplitted = value.split('/');
    return `${dateSplitted[2]}-${dateSplitted[1]}`;
  }

  public static getDateCompetenc(date: Date = new Date()) {
    return Intl.DateTimeFormat(DateUtil.PTBR_REGION).format(new Date(date.getFullYear(), date.getMonth(), 1));
  }

  public static getCurrentDate(): string {
    const date = new Date();
    const dateString = format(date, 'dd/MM/yyyy');

    return dateString;
  }

  public static getCurrentDateIgnoreHours(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return date;
  }
  
  // converte String DDMMYYYY em Date
  public static getDateDDMMYYYY(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year),Number(month)-1,Number(day));
  }

  public static addDaysDate(dateString: string, days: number, considerFistDay = false): string {
    let data = DateUtil.getDateDDMMYYYY(dateString);

    days = considerFistDay && days !== 0  ? days - 1 : days;
    data.setDate(data.getDate()+Number(days));
    return ("00" + data.getDate()).slice(-2)+'/'+("00"+(data.getMonth()+1)).slice(-2)+'/'+data.getFullYear();
  }

  public static getFirstDayOfMonth(dateString: string): string {
    let [day, month, year] = dateString.split('/');
    day = '01';

    const newDate = `${day}/${month}/${year}`;

    return newDate;
  }

  /** Converte uma data no formato YYYY-MM para DD/MM/YYYY */
  public static formatYYYYMMToDDMMYYYY(dateString: string): string {
    const [year, month] = dateString.split('-');
    const newDate = `01/${month}/${year}`;

    return newDate;
  }

  public static compareDates(
    date1: string | Date,
    date2: string | Date = null,
  ): number {
    if (!date2) {
      date2 = this.getCurrentDateIgnoreHours();
    }

    // Tratativas de Input
    if (typeof date1 === 'string') {
      date1 = DateUtil.getDateDDMMYYYY(date1);
    }

    if (typeof date2 === 'string') {
      date2 = DateUtil.getDateDDMMYYYY(date2);
    }

    const comparison = compareAsc(date1, date2);
    return comparison;
  }

  public static getDateIntervalFromString(dateInterval: string, intervalSeparator = DateUtil.dateIntervalSeparator) : DateInterval | false {
    const splittedDates = dateInterval.split(DateUtil.dateIntervalSeparator);

    if (Array.isArray(splittedDates) && splittedDates.length === 2) {
      const date1 = DateUtil.getDateDDMMYYYY(splittedDates[0]);
      const date2 = DateUtil.getDateDDMMYYYY(splittedDates[1]);

      return {
        initialDate: date1,
        finalDate: date2,
      }
    }

    return false;
  }

  public static isDateInInterval(date: string | Date, dateInterval: string = null, initialDate: Date | string = null, finalDate: Date | string = null): boolean {
    const normalizedInput = DateUtil.normalizeInputStringDate([date, initialDate, finalDate]);

    const dateNormalized = normalizedInput[0];

    if (dateInterval) {
      const normalizedInterval: DateInterval | false = DateUtil.getDateIntervalFromString(dateInterval);

      if (normalizedInterval) {
        return normalizedInterval.finalDate >= dateNormalized && dateNormalized >= normalizedInterval.initialDate;
      }
    } else {
      if (initialDate === null || finalDate) {
        return false;
      }
      const initialDateNormalized = normalizedInput[0];
      const finalDateNormalized = normalizedInput[0];
      return finalDateNormalized >= dateNormalized && dateNormalized >= initialDateNormalized;
    }

    return false;
  }

  public static isActualMonthInInterval(dateInterval: string) {
    const actualDate = new Date();

    const actualMonthInterval = DateUtil.getDaysInMonth(actualDate.getMonth(), actualDate.getFullYear());

    for (const date of actualMonthInterval) {
      const isDateInInterval = DateUtil.isDateInInterval(date, dateInterval);
      if (isDateInInterval) {
        return true;
      }
    }

    return false;
  }

  public static intervalIntersection(dateInterval1: string, dateInterval2: string) {
    const normalizedInterval1: DateInterval | false = DateUtil.getDateIntervalFromString(dateInterval1);
    const intersectionDays = [];

    if (normalizedInterval1) {
      const interval1Days = DateUtil.getDaysInInterval(normalizedInterval1.initialDate, normalizedInterval1.finalDate);

      for (const date of interval1Days) {
        const isDateInInterval = DateUtil.isDateInInterval(date, dateInterval2);
        if (isDateInInterval) {
          // return true;
          intersectionDays.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        }
      }
  
      // return false;
      return intersectionDays;
    }
    return false;
  }

  public static normalizeInputStringDate(datesArray: (string | Date)[]) {
    const normalizedInput = [];
    for (const date of datesArray) {
      if (typeof date === 'string') {
        const normalizedDate = DateUtil.getDateDDMMYYYY(date);
        normalizedInput.push(normalizedDate);
      } else {
        normalizedInput.push(date);
      }
      
    }

    return normalizedInput;
  }

  public static getDaysInMonth(month: number, year: number) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  public static getDaysInInterval(date1: string | Date, date2: string | Date) {
    const normalizedInput = DateUtil.normalizeInputStringDate([date1, date2]);

    const normalizedDate1 = normalizedInput[0];
    const normalizedDate2 = normalizedInput[1];

    const currentDate = new Date(normalizedDate1.getFullYear(), normalizedDate1.getMonth(), normalizedDate1.getDate());

    var days = [];
    while (currentDate <= normalizedDate2) {
      days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  }

  public static sortDateArray(
    dates: string[],
    ascending = true
  ): string [] {
    let sortedDates = dates.sort(DateUtil.compareDates);

    if (!ascending) {
      sortedDates.reverse();
    }

    return sortedDates;
  }

  public static dateToNumber(
    date: string
  ): number {
    const dateIsValid = DateUtil.isValid(date);

    if (!dateIsValid) {
      const message = `The date ${date} is not valid!`;
      throw new Error(message);
    }

    const [ day, month, year ] = date.split('/');

    const formattedDate = year + month + day;
    const dateNumber = parseInt(formattedDate, 10);

    return dateNumber;
  }

  public static differenceInDays(
    dateString1: string,
    dateString2: string | undefined,
  ): number {
    if (!dateString2) {
      dateString2 = DateUtil.getCurrentDate();
    }

    const date1 = DateUtil.stringToDate(dateString1);
    const date2 = DateUtil.stringToDate(dateString2);

    const difference = differenceInDays(date1, date2);

    return difference;
  }

  public static stringToDate(
    dateString: string,
  ): Date {
    const [day, month, year] = dateString.split('/');
    const monthIndex = parseInt(month, 10) - 1;

    const dayNumber = parseInt(day, 10);
    const yearNumber = parseInt(year, 10);

    const date = new Date(yearNumber, monthIndex, dayNumber);

    return date;
  }

  public static dateTimeStringToDateString(dateTime: string, dateStringLength = DateUtil.DATESTRINGLENGTH) {
    return dateTime.substring(0, dateStringLength);
  }

  public static dateTimeStringToDateTruncate(dateTime: string, dateStringLength = DateUtil.DATESTRINGLENGTH) {
    const dateString = this.dateTimeStringToDateString(dateTime, dateStringLength);
    return DateUtil.stringToDate(dateString);
  }

  public static getCurrentTime(
    format = 'hh:mm:ss',
  ): string {
    const dateTime = new Date();

    const hour = dateTime.getHours().toString().padStart(2, '0');
    const minute = dateTime.getMinutes().toString().padStart(2, '0');
    const second = dateTime.getSeconds().toString().padStart(2, '0');

    let time = format;
    time = time.replace('hh', hour);
    time = time.replace('mm', minute);
    time = time.replace('ss', second);

    return time;
  }
}



export default DateUtil;
