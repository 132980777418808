class VueUtils {
  public static propertyIsTrue(propertyValue: boolean | string) {
    const isTrue =
      propertyValue === true ||
      propertyValue === 'true' ||
      propertyValue === '';

    return isTrue;
  }

  public static getBreakpoint(): string {
    const width = window.innerWidth;

    if (width < 600) {
      return 'xs';
    }

    if (width < 960) {
      return 'sm';
    }

    if (width < 1264) {
      return 'md';
    }

    if (width < 1904) {
      return 'lg';
    }

    return 'xl';
  }
}

export default VueUtils;