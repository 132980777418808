class Constants {
  public static readonly PROJECT_PATH = '../';

  public static readonly BACKEND_PATH = 'backend/index.php';

  public static readonly LOGIN_BACKEND_PATH = 'backend_login/index.php';

  public static readonly PRODUCT_VERSION = '0.25.0';

  public static readonly PRODUCT_ID = '1061';

  public static readonly GOOGLE_AUTH2_ID = '750350139297-81kfactguh9kvcjrhif4knig3gh10pqb.apps.googleusercontent.com';

  public static readonly LINKEDIN_CLIENT_ID = '78fogktrebmsx1';
}

export default Constants;
