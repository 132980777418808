import Vue from 'vue';
import './plugins/axios';
import './plugins/toast';
import './plugins/vmask';
import './plugins/confirm';
import './plugins/loading';
import './plugins/cropper';
import './plugins/vue-rx';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './plugins/request';
import store from './store/store';
import LinkedInAuth from './assets/ts/utils/LinkedInAuth';

require('@/scss/main.scss');

if (LinkedInAuth.isLinkedInCallback()) {
  LinkedInAuth.verifyToken();
} else {
  Vue.config.productionTip = false;
  (window as any).debugBreakpoints = false;

  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}
