import { AxiosError, AxiosResponse } from 'axios';

class ExpectedError extends Error {
  public static throwResponseError(
    response: AxiosResponse,
  ) {
    if (typeof response.data === 'string') {
      throw new Error(response.data);
    }

    if (response.data.messages) {
      let message = response.data.messages[0].message;
      message = message.replace('(HCMSERVICES) ', '');

      throw new ExpectedError(message);
    }
  }

  public static isExpectedError(
    err: Error,
  ): boolean {
    return err instanceof ExpectedError;
  }

  public static getResponseErrorMessage(
    err: Error,
    defaultMessage: string,
  ): string {
    const message = this.isExpectedError(err) ?
      err.message : defaultMessage;

    return message;
  }

  public static getErrorMessage(
    err: any,
    defaultMessage = 'Error',
  ): string {
    const errorMessage = err.response ?
      err.response.data.error :
      err.message;

    return errorMessage || defaultMessage;
  }
}

export default ExpectedError;
