import { Vue, Component } from 'vue-property-decorator';
import DashboardController from '@/assets/ts/controller/DashboardController';
import StatusCadastro from '@/assets/ts/interfaces/dashboard/StatusCadastro';
import VagasRelacionadas from '@/assets/ts/interfaces/dashboard/VagasRelacionadas';
import UltimaAtualizacao from '@/assets/ts/interfaces/dashboard/UltimaAtualizacao';

@Component({
  name: 'Dashboard',
  components: {},
})
class Dashboard extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  // #endregion

  // #region [ DATA ]
  public statusCard = {
    data: [] as StatusCadastro[],
    loading: false,
    error: false,
  };

  public vagasCard = {
    data: null as VagasRelacionadas,
    loading: false,
    error: false,
  };

  public atualizacaoCard = {
    data: null as UltimaAtualizacao,
    loading: false,
    error: false,
  };
  // #endregion

  // #region [ COMPUTED ]
  public get vagasFavoritasText(): string {
    const text = this.getVagasBtnText('Favoritas', this.vagasCard?.data?.FAVORITAS);
    return text;
  }

  public get vagasCandidatadoText(): string {
    const text = this.getVagasBtnText('Candidatadas', this.vagasCard?.data?.CANDIDATADAS);
    return text;
  }

  public get vagasDisponiveisText(): string {
    const text = this.getVagasBtnText('Disponíveis', this.vagasCard?.data?.DISPONIVEIS);
    return text;
  }

  public get ultimaAtualizacaoText(): string {
    if (!this.atualizacaoCard.data) {
      return '-';
    }

    const diaSemana = this.atualizacaoCard.data.DIA;
    const dia = this.atualizacaoCard.data.DTULTATUALIZACAO;

    const text = `${diaSemana}, ${dia}`;

    return text;
  }

  public get textDiasSemAtualizacao(): string {
    if (!this.atualizacaoCard.data) {
      return '';
    }

    const atraso = this.atualizacaoCard.data.ATRASO;
    const text = `Há ${atraso} dias sem atualização!`;

    return text;
  }

  public get loading(): boolean {
    const loading =
      this.atualizacaoCard.loading
      || this.vagasCard.loading
      || this.statusCard.loading;

    return loading;
  }
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  private created() {
    (window as any).dashboard = this;

    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  public callback(): void {
    //
  }

  private reload() {
    this.loadStatusCadastro();
    this.loadQuantidadeVagas();
    this.loadAtualizacaoData();
  }

  private async loadStatusCadastro() {
    this.statusCard.loading = true;
    this.statusCard.error = false;

    try {
      this.statusCard.data = await DashboardController.getStatusCadastro(false);
    } catch {
      this.statusCard.error = true;
    } finally {
      this.statusCard.loading = false;
    }
  }

  public getVagasBtnText(
    name: string,
    qtVagas: string,
  ): string {
    const text = qtVagas ?
      `${name} (${qtVagas})` :
      name;

    return text;
  }

  private async loadQuantidadeVagas() {
    this.vagasCard.loading = true;
    this.vagasCard.error = false;

    try {
      this.vagasCard.data = await DashboardController.getQtVagasRelacionadas(false);
    } catch {
      this.vagasCard.error = true;
    } finally {
      this.vagasCard.loading = false;
    }
  }

  private async loadAtualizacaoData() {
    this.atualizacaoCard.loading = true;
    this.atualizacaoCard.error = false;

    try {
      this.atualizacaoCard.data = await DashboardController.getUltimaAtualizacao(false);
    } catch {
      this.atualizacaoCard.error = true;
    } finally {
      this.atualizacaoCard.loading = false;
    }
  }
  // #endregion
}

export default Dashboard;
