






















































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { format } from 'date-fns';
import Util from '../assets/utils/Util';
import VueUtils from '../assets/utils/VueUtils';
import TkField from './fields/TkField.vue';
import TkToolbar from './grids/TkToolbar.vue';
import HttpRequest from '../assets/utils/HttpRequest';
import SnackbarUtils from '../assets/utils/SnackbarUtils';

import Field from '../assets/interfaces/Field';
import ToolbarAction from '../assets/interfaces/ToolbarAction';
import { toLength } from 'lodash';
import Dictionary from '../assets/interfaces/Dictionary';

@Component({
  name: 'TkForm',
  components: {
    TkField,
    TkToolbar,
  },
})
class TkForm extends Vue {
  //#region [ PROPS ]
  /**
   * The actions to be shown in the toolbar.
   */
  @Prop({ default: ((): any[] => []) })
  private readonly actions: (ToolbarAction | string)[];

  @Prop({ default: () => {} })
  private readonly backup: any;

  /**
   * Reduces all fields' height.
   */
  @Prop({ default: false })
  private readonly dense: boolean;

  /**
   * The parameters to be send in the request.
   */
  @Prop({ default: ((): any => {}) })
  private readonly filters: any;

  @Prop({ default: false })
  private readonly hideDetails: boolean;

  /**
   * The fields to be displayed in the form.
   */
  @Prop({ default: ((): Field[] => []) })
  private readonly fields: Field[];

  @Prop({ default: false })
  private readonly loading: boolean;

  /**
   * Defines the form's display mode. Can be either "view" or "edit".
   */
  @Prop({ default: 'view' })
  private readonly mode: string;

  @Prop({ default: false })
  private readonly outlined: string | boolean;

  /** Defines if the form's will be fetched on its creation. */
  @Prop({ default: false })
  private readonly reloadOnCreation: boolean;

  /** A trigger that reloads the form when its value changes. */
  @Prop({ default: 0 })
  private readonly reloadTrigger: number;

  /**
   * The back-end route to the form's data.
   */
  @Prop({ default: '' })
  private readonly route: string;

  /**
   * Object which has the form's data
   */
  @Prop({ default: () => {} })
  private readonly value: any;
  //#endregion

  // #region [ EVENTS ]
  @Emit('blur')
  private emitBlur(fieldName: string, value: any) {
    return [fieldName, value];
  }

  @Emit('field-input')
  private emitFieldInput(fieldName: string, value: any) {
    return [fieldName, value];
  }

  @Emit('row-input')
  private emitRowInput() {
    return this.internalValue;
  }

  @Emit('input')
  private emitInput(row: any) {
    // Propriedades para sempre serem retornadas na row
    row.validate = this.validate;
    return row;
  }

  @Emit('validateFields')
  private emitValidate() {
    return this.internalValue.__is_valid;
  }

  @Emit('update:mode')
  private emitUpdateMode() {
    return this.internalMode;
  }

  @Emit('cancel')
  private emitCancel() {}

  @Emit('edit')
  private emitEdit() {}
  // #endregion

  //#region [DATA]
  private internalValue: any = {};

  private triggerUpdateFields = 0;

  private internalLoading: boolean = false;
  private internalMode = 'view';

  private internalFields: Field[] = [];

  private readonly defaultActions: Dictionary<ToolbarAction> = {
    edit: {
      name: 'edit',
      label: 'Editar',
      icon: 'mdi-pencil',
      color: 'blue',
      mode: 'view',
      onClick: this.edit,
    },
    cancel: {
      name: 'cancel',
      label: 'Cancelar',
      icon: 'mdi-close-circle',
      color: 'red',
      mode: 'edit',
      onClick: this.cancel,
    },
  }
  //#endregion

  // #region [COMPUTED]
  private get denseProp() {
    const dense = VueUtils.propertyIsTrue(this.dense);

    return dense;
  }

  private get isLoading(): boolean {
    return this.loading || this.internalLoading;
  }

  private get toolbarActions(): ToolbarAction[] {
    let actions = this.actions.map((action) => {
      if (typeof action === 'string') {
        return this.defaultActions[action];
      }

      return action;
    });

    actions = actions.filter((action) => {
      return action.mode === this.mode || Util.isUndefinedOrNull(action.mode)
    });

    return actions;
  }
  // #endregion

  //#region [WATCHERS]
  @Watch('value', { deep: true, immediate: true })
  private valueOnChange() {

    // Generate __is_valid on row
    this.validate();

    if (Util.deepCompare(this.value, this.internalValue)) {
      return;
    }

    this.internalValue = this.value ?
      Util.deepCopy(this.value) :
      {};

    this.emitInput(this.internalValue);
  }

  @Watch('reloadTrigger')
  private reloadValue() {
    this.setValueFields(this.value);
    this.$forceUpdate();
  }

  @Watch('fields', { deep: true, immediate: true })
  private fieldsOnChange() {
    let fields = Util.deepCopy(this.fields);

    fields.forEach((field: Field) => {
      field.error = field.error || false;
    });

    fields = fields.filter((field) => {
      return field.isVisible !== false;
    });

    this.internalFields = fields;
    this.validate();
  }

  @Watch('mode', { immediate: true })
  private modeOnChange() {
    this.internalMode = this.mode;
  }
  //#endregion

  // #region [ LIFECYCLE ]
  private async created() {

    if (this.reloadOnCreation) {
      await this.reload();
    }

    this.triggerUpdateFields++;
  }

  // #endregion

  //#region [METHODS]
  private fieldOnInput(field: Field, value: any) {
    const fieldName = field.name;

    this.validate();
    this.setOutData(field, value);
    this.emitValidate();
    this.emitInput(this.internalValue);
    this.emitFieldInput(fieldName, value);
    this.emitRowInput();

    if (field.onInput) {
      field.onInput(value);
    }
  }

  private fieldOnBlur(fieldName: string, value: any) {
    this.emitBlur(fieldName, value);
  }

  private setOutData(field: Field, value: any) {
    const hasOutData = !!field.outData;

    if (!hasOutData) {
      return;
    }

    const outDataKeys = Object.keys(field.outData);
    const selected = Util.findSelectedItem(field, value);

    outDataKeys.forEach((key) => {
      const keyValue = field.outData[key];

      const selectedValue = selected === undefined ?
        undefined :
        selected[keyValue];

      this.internalValue[key] = selectedValue;
    });
  }

  private fieldIsDisabled(field: any) {
    const disabled =
      field.readonly === true ||
      this.mode === 'view';

    return disabled;
  }

  private validate(
    setErrorColor = false,
  ): boolean {
    const isValid = this.internalFields.reduce((accumulator, field: Field) => {
      const fieldValue = this.internalValue[field.name];

      const fieldIsValid =
        !Util.isEmptyOrBlank(fieldValue) ||
        field.required !== true;

      if (setErrorColor && !fieldIsValid) {
        field.error = true;
      }

      if (fieldIsValid) {
        field.error = false;
      }

      return accumulator && fieldIsValid;
    }, true);

    this.internalValue.__is_valid = isValid;

    return isValid;
  }

  private async reload() {
    const params = {
      requestType: 'FilterData',
      ...this.filters,
    };

    try {
      this.internalLoading = true;
      const response = await HttpRequest.get(this.route, params, false);

      const rows =
        response.data.dataset.data ||
        response.data.dataset[this.route] ||
        [];
      this.internalLoading = false;
      this.emitInput(rows);
      this.$emit('afterReload', rows);
      this.setValueFields(rows);
      /*Util.redefineArray(this.tableRows, rows);
      
      this.createTableRows();
      this.loading = false;
      this.insertFooterRow();
      this.$emit('afterReload', this.tableRows);
      this.triggerUpdateFields++;*/
    } catch(err) {
      this.internalLoading = false;

      SnackbarUtils.showMessage('Não foi possível carregar os dados.', 'error');
    }
  }

  private setValueFields(rows: any){
    Object.keys(rows).forEach((key) => {
        if(!!rows[key]){
          this.internalValue[key] = rows[key];
        }
    });
  }

  private getPropField(name: string): Field {
    const propField = this.fields.find((field) => {
      return field.name === name;
    });

    return propField;
  }

  private edit(): void {
    this.internalMode = 'edit';
    this.emitUpdateMode();
  }

  private cancel(): void {
    this.internalMode = 'view';
    this.reset();
    this.emitUpdateMode();
    this.emitCancel();
  }

  private reset(): void {
    this.internalValue = Util.deepCopy(this.backup);
    this.emitInput(this.internalValue);
    this.emitEdit();
  }
  //#endregion
}

export default TkForm;
