import Dictionary from '../interfaces/Dictionary';

class StringUtils {
  public static includesIgnoreCase(
    target: string,
    search: string,
  ): boolean {
    return target.toLowerCase().includes(search.toLowerCase());
  }

  public static includesIgnoreSpecial(
    target: string,
    search: string,
  ): boolean {
    const cleanTarget = this.removeSpecialChars(this.removeAccents(target.toLowerCase()));
    const cleanSearch = this.removeSpecialChars(this.removeAccents(search.toLowerCase()));

    return cleanTarget.includes(cleanSearch);
  }

  public static removeSpecialChars(str: string): string {
    // https://stackoverflow.com/questions/1983767/only-keep-a-z-0-9-and-remove-other-characters-from-string-using-javascript
    return str.replace(/[^a-zA-Z0-9]+/g, '-');
  }

  public static removeAccents(str: string): string {
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public static strtr(
    target: string,
    map: Dictionary,
  ): string {
    const wordsToReplace = Object.keys(map);

    const result = wordsToReplace.reduce((accumulator, wordToReplace) => {
      const replacer = map[wordToReplace];
      const newString = accumulator.replace(wordToReplace, replacer);

      return newString;
    }, target);

    return result;
  }

  public static insertStringAtIndex(
    target: string,
    str: string,
    index: number
  ): string {
    const newStr = target.substr(0, index) + str + target.substr(index, target.length);
    return newStr;
  }

  public static insertDashInPhone(
    phoneNumber: string,
  ): string {
    const lengthIndexMap = {
      8: 4, // 25550355
      9: 5, // 993465327
      12: 8, // (31)25550355
      13: 9, // (31)993465327
    };

    const dashIndex = lengthIndexMap[phoneNumber.length];
    const newNumber = this.insertStringAtIndex(phoneNumber, '-', dashIndex);

    return newNumber;
  }
}

export default StringUtils;
